const index = (editor) => {
  editor.BlockManager.add("TableList", {
    label: "<div class='gjs-fonts gjs-f-b1'>Table 2 columns 2 rows</div>",
    category: 'Table Default',
    content: `<table class="table2-list">
            <tr class="row2s">
              <td class="columns2">
                <p>Student’s name</p>
                <p>學生姓名</p>
              </td>
              <td class="columns2">
                <p>
                XXXXXXXXX
                </p>
              </td>
              <td class="columns2">
                <p>Preferred name</p>
                <p>常用姓名</p>
              </td>
              <td class="columns2">
                <p>
                XXXXXXXXX
                </p>
              </td>
            </tr>
            <tr class="row2s">
              <td class="columns2">
                <p>IEP Coordinator</p>
                <p>個別學習需要導師</p>
              </td>
              <td class="columns2">
                <p>XXXXXXXXX</p>
              </td>
              <td class="columns2">
                <p>ILP Date</p>
                <p>日期</p>
              </td>
              <td class="columns2">
                <p>October 12, 2018</p>
              </td>
            </tr>
        </table>
        <style>
          .table2-list {
            width: 100%;
          }
          .row2s {
            display: flex;
          }
          .table2-list .row2s:not(:first-child) .columns2 {
            border-top: none;
          }
          .columns2 p {
            margin:0px;
            padding: 5px;
          }
          .columns2 p:nth-child(1) {
            font-weight: 600;
          }
          .columns2 {
            border: 1px solid #000; 
            text-align: left;
            width: 100%;
          }
          .columns2:not(:first-child) {
            border-left: none;
          }
        </style>`,
  });

  editor.BlockManager.add("Table1Column", {
    label: "<div class='gjs-fonts gjs-f-b1'>Table 1 columns </div>",
    category: 'Table Default',
    content: `<table class="table1-list">
            <tr class="row1s">
              <td class="columns1">
                <p>Areas of concern</p>
                <p>需要被關注的項目</p>
              </td>
            </tr>
            <tr class="row1s">
              <td class="columns1">
                <p> ● Bullying from peers 來自同學們的欺凌 </p>
                <p> ● Anxiety especially with written tasks\/expressing his own ideas or thoughts, as he was taunted about this in
                primary 對寫作任務/創意寫作任務/表達自己的想法和觀點上很焦慮因為他在小學時有因此被嘲弄的經歷 </p>
                <p> ● Very cautious about making mistakes to the point where he will freeze and be unable to carry on with the task at
                hand 十分小心害怕犯錯甚至有時會無法對外界作出反應甚至不能繼續完成任務 </p>
                <p> ● Rigidity in rules that confines him from performing 對規矩特別地固執以致局限他的表現 </p>
                <p> ● Weak processing speed, oral expression and word fluency 在理解速度,口頭表達以及口語流利度等方面較慢 </p>
                <p> ● Weak when completing tasks under time-constraints 當要求在一定的時間限制內完成任務時,表現不佳 </p>
                <p> ● Struggles to tell the difference between playful teasing and more serious bullying 無法辨別玩笑似的戲弄和嚴重 的欺凌 </p>
                <p> ● Social anxiety – XXXXX internalises all negative emotions 社交焦慮-XXXXX將所有的負面情緒內化 </p>
                </p>
              </td>
            </tr>
        </table>
        <style>
        .table1-list {
          width: 100%;
        }
        .row1s {
          display: flex;
          flex-direction: column;
        }
        .table1-list .rows:not(:first-child) .columns1 {
          border-top: none;
        }
        .table1-list .row1s .columns1:not(:first-child) {
          border-top: none;
        }
        .columns1 {
          border: 1px solid #000; 
          text-align: left;
          width: 100%;
        }
        .columns1 p:nth-child(1) {
          font-weight: 600;
        }
        .columns1 p {
          margin:0px;
          padding: 5px;
        }
        </style>`,
  });
}
export default index;