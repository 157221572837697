export const getFnToGoalHtml = (functionName = 'toGoalHtml', textFieldFunction, datePickerFunction) => {
    return `const ${functionName} = (value = []) => {
        return \`
            <div>
                \${value?.map(g => \`
                        <div class="MuiGoal-boxGoal">
                            <div class="MuiGrid-root MuiGoal-grid">
                                <div class="MuiGoal-objectives">
                                    <div style="padding-right: 20px;">
                                        \${${datePickerFunction}?.({
                                            label: 'Start Date',
                                            value: g?.startTime
                                        })}    
                                    </div>
                                    <div style="padding-right: 20px;">
                                        \${${datePickerFunction}?.({
                                            label: 'End Date',
                                            value: g?.endTime
                                        })}
                                    </div>
                                    <div>
                                        \${${textFieldFunction}?.({
                                            label: 'Write something',
                                            value: g?.detailGoal
                                        })}
                                    </div>
                                </div>
                            </div>
                            \${g?.objectives.map((i, index) => \`
                                    <div class="MuiGrid-root MuiGoal-gridNext">
                                        <div class="MuiGoal-objectives">
                                            <div style="padding-right: 20px;">
                                                \${${datePickerFunction}?.({
                                                    label: 'Start Date',
                                                    value: i?.startTime
                                                })}    
                                            </div>
                                            <div style="padding-right: 20px;">
                                                \${${datePickerFunction}?.({
                                                    label: 'End Date',
                                                    value: i?.endTime
                                                })}
                                            </div>
                                            <div>
                                                \${${textFieldFunction}?.({
                                                    label: 'Write something',
                                                    value: i?.detailObjectives
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                \`).join('\\n')
                    }
                    </div>
                \`)}
            </div>
        \`
    }`
}

export const toGoalHtml = (functionName = 'toGoalHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('GoalObjectiveMUI')
        ${variablesName}.forEach(el => {
            const id = el.getAttribute('id')
            const value = data?.find(i => i.id === id)
            const newItem = document.createElement('div');
            newItem.innerHTML = ${functionName}?.(value?.value === \`[\${value?.name}_\${value?.id}]\` ? [] : value?.value)
            el?.parentElement.replaceChild(newItem, el)
        })
    `
}