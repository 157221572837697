export const getFnToTableUploadFileHtml = (
  functionName = "toTableUploadFileHtml"
) => {
  return `const ${functionName} = ({ value }) => {
    return \`
      <table class="MuiTextFieldTable-customers">
          <thead>
              <tr>
                  <th>Name</th>
                  <th>Time uploaded</th>
                  <th>Type</th>
              </tr>
          </thead>
          <tbody>
              \${value
                .map(
                  (i) => \`
                      <tr>
                          <td>
                              \${
                                i.name
                              }
                          </td>
                          <td>
                              \${i.time}
                          </td>
                          <td>
                            \${
                              i.type
                            }    
                          </td> 
                      </tr>
                  \`
                )
                .join("")}
          </tbody>
      </table>
    \`;
  };`;
};

export const toTableUploadFileHtml = (
  functionName = "toTableUploadFileHtml",
  variablesName
) => {
  return `
      const ${variablesName} = document.querySelectorAll("TableUploadFile");
      tableUploadFiles?.forEach((el) => {
        const id = el.getAttribute("id");
        const value = data?.find((i) => i.id === id);
        const htmlContent = ${functionName}?.({
          value: value?.value === \`[\${value?.name}_\${value?.id}]\`
              ? []
              : value?.value || [],
        });
        const newItem = document.createElement("div");
        newItem.innerHTML = htmlContent;
        el?.parentElement.replaceChild(newItem, el);
      });
    `;
};
