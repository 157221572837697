import React, { useState, useEffect } from "react"
import FolderIcon from "@material-ui/icons/Folder"
import DeleteIcon from '@material-ui/icons/Delete';
import { writeDataChange } from "../../utils"
import { 
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton
} from "@material-ui/core"


const FileUploadMui = (props) => {
  const { name, label, id, value } = props;
  const [listFileVersion, setListFileVersion] = useState([]);

  useEffect(() => {
    if (value == `[${name}_${id}]`) {
        setListFileVersion([])
    } else {
        setListFileVersion(value);
    }
  }, [value])

  const handleFileChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const dataFile = event.target.files;
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (validImageTypes.includes(dataFile.type)) {
          throw new Error("Not an File")
        }
        window.parent.postMessage({ eventName: 'uploadFileIEPOldVerion', dataFile: dataFile }, '*');
    } catch (err) {
       console.log(err)
    }
  }

  window.addEventListener('message', function(event) {
    if (event.data.eventName == 'parentSendFileVersion') {
      const fileData = event.data.fileData;
      if (!!fileData) {
          let resultData = [...listFileVersion, ...fileData];
            setListFileVersion(resultData);
            let obj = {
                id: id,
                name: name,
                label: label,
                value: resultData,
                type: 'FileUploadMui'
            }
            writeDataChange(obj)
            let data = localStorage.getItem("gjs-inputGrapes");
            window.parent.postMessage({ eventName: 'marioChange', data : data}, "*");
      }
    }
  })

  const downloadFileIep = (keyFile, nameFile) => {
    window.parent.postMessage({ eventName: 'downloadFileIEPOldVerion', keyFile: keyFile, nameFile: nameFile }, '*');
  }

  const removeItem = (keyFile) => {
      const afterResult = listFileVersion.filter((item) => item.keyFile !== keyFile);
      setListFileVersion([...afterResult]);
      let obj = {
        id: id,
        name: name,
        label: label,
        value: afterResult,
        type: 'FileUploadMui'
      }
      writeDataChange(obj)
      let data = localStorage.getItem("gjs-inputGrapes");
      window.parent.postMessage({ eventName: 'marioChange', data : data}, "*");
    }

  useEffect(() => {
    let obj = {
      id: id,
      name: name,
      label: label,
      value: value,
      type: 'FileUploadMui'
    }
    writeDataChange(obj)
  }, [name, label])

  return (
    <div>
      <div className="button-upload">
        Upload File
        <input id="uploadFile" type="file" multiple onChange={handleFileChange}/>
      </div>
        <List
            className="list-version"
        >
            {!!listFileVersion && listFileVersion.length > 0 && listFileVersion.map?.((record, key) => (
                <ListItem
                    key={key}
                    onClick={() =>
                        downloadFileIep(
                          record.keyFile,
                          record.nameFile
                        )
                    }
                    className="item-version"
                >
                    <ListItemIcon>
                        <FolderIcon />
                    </ListItemIcon>
                    <ListItemText
                        className="item-version-text"
                        primary={
                          record.nameFile
                        }
                        secondary={
                          record.typeFile
                        }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete" onClick={() => removeItem(record.keyFile)}>
                        <DeleteIcon />
                      </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
            ))}
        </List>
    </div>
    
  );
}

export default FileUploadMui;