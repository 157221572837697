import ReactDOM from "react-dom";

import { ServerStyleSheets } from "@material-ui/styles";

import baseReactComponent from "../base-react-component";

import TextField from "./../TextField/index";
import DatePicker from "../DatePicker/index"
import Select from "./../Select/index"
import Radio from "./../Radio/index"
import Checkbox from "./../Checkbox/index"
import GoalObject from "./../GoalExample/index"
import ImageMUI from "./../Image/index"
import FileUploadMui from "../FileUpload/index";
import TableMultiColumns from "../Table/tableIndex";
import AvatarImageMui from "../AvatarImage/index";


const sheets = new ServerStyleSheets();

const index = (editor) => {
  const {
    baseReactComponentModel,
    baseReactComponentView
  } = baseReactComponent(editor);

  const model = {
    ...baseReactComponentModel
  };

  const view = {
    ...baseReactComponentView,
    mount: function(component) {
      const s = sheets.collect(component);
      ReactDOM.render(s, this.getRenderTarget());
      const css = sheets.toString();

      const style = document.createElement("style");
      style.innerHTML = css;
      this.em
        .get("Canvas")
        .getDocument()
        .head.appendChild(style);
      return this;
    }
  };

  const params = { editor, model, view };
  TextField(params);
  DatePicker(params);
  Select(params);
  Radio(params);
  Checkbox(params);
  GoalObject(params);
  ImageMUI(params);
  FileUploadMui(params);
  TableMultiColumns(params);
  AvatarImageMui(params);
};

export default index;
