import React, { useState, useEffect, useRef } from "react";
import { writeDataChange, getFileUrl } from "../../utils"

const ImageMui = (props) => {
  const { name, label, id, required, value } = props;
  const [urlImage, setUrlImage] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (value == `[${name}_${id}]`) {
      setUrlImage("/images/uploadfile.png")
    } else {
      setUrlImage(value);
    }
  }, [value])

  const handleFileChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const dataFile = event.target.files[0];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (!validImageTypes.includes(dataFile.type)) {
          throw new Error("Not an image")
        }

        let objOld = {
          id: id,
          name: name,
          label: label,
          required: required,
          type: 'ImageMUI'
        }
        localStorage.setItem("currentUpload", JSON.stringify(objOld));
        window.parent.postMessage({ eventName: 'uploadFileSignature', dataFile: dataFile }, '*');
    } catch (err) {
       console.log(err)
    }
  }

  window.addEventListener('message', function(event) {
    if (event.data.eventName == 'parentSendImage') {
      const dataImage = event.data.imageData;
      if (!!dataImage) {
        const urlFile = getFileUrl(dataImage.domain, dataImage.keyFile);
        let currentUpload = JSON.parse(localStorage.getItem("currentUpload"));
        if (currentUpload.id == id) {
          let obj = {
            id: id,
            name: name,
            label: label,
            value: urlFile,
            required: required,
            type: 'ImageMUI'
          }
          setUrlImage(urlFile);
          writeDataChange(obj)
          let data = localStorage.getItem("gjs-inputGrapes");
          window.parent.postMessage({ eventName: 'marioChange', data : data}, dataImage.domain);
        }
        
      }
    }
  })

  const handleClick = () => {
    inputRef.current.click();
  }

  useEffect(() => {
    let obj = {
      id: id,
      name: name,
      label: label,
      required: required,
      value: value,
      type: 'ImageMUI'
    }
    writeDataChange(obj)
  }, [name, label, required])
  return (
    <div>
      <input
        type="file"
        ref={inputRef}
        onChange={(event) => {
          handleFileChange(event)
          event.target.value = null;
        }}
        style={{display: "none" }}
        accept="image/*"
      />
      <div className="wrapper-item">
        <h3 className="file-title">{label} { !!required && <i className="required-icon">*</i>}</h3>
        <div className="file-wrapper" onClick={handleClick}>
          <img className="no-file-img" src={urlImage} alt="upload-img" />
          <h4 className="upload-image">Upload Image</h4>
          <h5 className="type-file">Supported image types: PNG, JPEG, WEBP</h5>
        </div>
      </div>
    </div>
    
  );
}

export default ImageMui;