import RadioMui from "./RadioMui"
import { stringGenerateRandom } from "../../utils"

const index = ({ editor, model, view }) => {
    const idString = stringGenerateRandom(15);
    editor.BlockManager.add("RadioMui", {
      label: "<div class='gjs-fonts gjs-f-b1'>RadioMui</div>",
      category: 'Form MUI',
      content: `<RadioMui label="This is label radio" options="value:name" name="outlined" value="[outlined_${idString}]" id="${idString}"/>`
    });
  
    editor.DomComponents.addType("RadioMui", {
      model: {
        ...model,
        defaults: {
          component: RadioMui,
          stylable: true,
          editable: true,
          void: true,
          droppable: false,
          traits: [
            {
              type: "text",
              label: "Id",
              name: "id"
            },
            {
              type: "text",
              label: "Value",
              name: "value",
            },
            {
                type: "text",
                label: "Name",
                name: "name"
            },
            {
                type: "text",
                label: "Label",
                name: "label"
            },
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            {
                type: "text",
                label: "Options",
                name: "options"
            },
          ]
        }
      },
      view,
      isComponent: el => el.tagName === "RADIOMUI"
    });
  }
  export default index;