import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { updateHTMLIEP, getHTMLIEP, getTemplateIEPByType } from "../services"
import TableList from "../components/Table/index"
import Template from "../components/Template/index"
import Grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import grapesjsPresetWebpage from 'grapesjs-preset-webpage';
import MuiComponents from "./../components/mui-components";
import { DATE_MIN_VALUE, typeEnumTemplate } from "../utils/type"
import { uploadImageIEP } from '../services/index'
import { getFileUrl, stringGenerateRandom } from "../utils/index"
import "../components/app.css";
import HeaderDefault from "../components/Template/header"
import plugin from 'grapesjs-tui-image-editor';

const ONE_MINUTE_MILLISECONDS = 60*1000;

const useGrapesjsMain = () => {
    const { templateId } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const domain = urlParams.get('domain');
    const typeId = urlParams.get('typeId');
    const ref = useRef(false);
    const intervalRef = useRef(null)
    const loadGrapesJs = async () => {
        // window.CKEDITOR.dtd.$editable.span = 1
        // window.CKEDITOR.dtd.$editable.a = 1
        const data = await getHTMLTemplate();
        if (data != null) {
            localStorage.setItem("gjs-html", data?.htmlSchool || '')
            localStorage.setItem("gjs-css", data?.cssSchool || '')
            localStorage.setItem("gjs-components", data?.componentSchool || '')
            localStorage.setItem("gjs-styles", data?.styleSchool || '')
            localStorage.setItem("gjs-assets", data?.assetSchool || '')
            localStorage.setItem("gjs-inputGrapes", data?.inputIEPSchool || '')
            ref.current = data?.isPublish;
            window.parent.postMessage({ eventName: 'changeBuilderPage', data : data}, '*');
        }

        const editor = await Grapesjs.init({
                // Indicate where to init the editor. You can also pass an HTMLElement
            container: "#gjs",
            // Get the content for the canvas directly from the element
            // As an alternative we could use: `components: '<h1>Hello World Component!</h1>'`,
            fromElement: true,
            // Size of the editor
            height: "100vh",
            width: "auto",
            // Disable the storage manager for the moment
            storageManager: {
            type: "local",
            autosave: true, // Store data automatically
            autoload: true, // Autoload stored data on init
            stepsBeforeSave: 0
            },
            assetManager: {
                storageType  	: '',
                storeOnChange	: false,
                storeAfterUpload  : false,
                assets    	: [],
                uploadFile: async function(e) {
                    var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
                    var formData = new FormData();
                    formData.append('file', files[0]) //containing all the selected images from local
                    const res = await uploadImageIEP(domain, formData);
                    const data = res.data;
                    const images = {
                        'name': data['name'],
                        'type': data['type'],
                        'src': getFileUrl(domain, data['key']),
                        'height': 350,
                        'width': 250
                    };
                    editor.AssetManager.add(images);
                }
            },
            // Avoid any default panel
            plugins: [grapesjsPresetWebpage, MuiComponents, plugin],
            pluginsOpts: {
            [plugin]: { 
                config: {
                    includeUI: {
                      initMenu: 'filter',
                    },
                },
                onApply: (imageEditor, imageModel) => {
                    const dataUrl = imageEditor.toDataURL();
                    const ext = dataUrl.substring("data:image/".length, dataUrl.indexOf(";base64"))
                    fetch(dataUrl)
                    .then(res => res.blob())
                    .then(async (file) => {
                        var formData = new FormData();
                        formData.append('file', file, `${new Date().getTime()}.${ext}`)
                        const res = await uploadImageIEP(domain, formData);
                        const data = res.data;
                        const images = {
                            'name': data['name'],
                            'type': data['type'],
                            'src': getFileUrl(domain, data['key']),
                            'height': 350,
                            'width': 250
                        };
                        editor.AssetManager.add(images)
                        imageModel.set('src', getFileUrl(domain, data['key'])) // Update the image component
                    })
                },
                addToAssets: true,
                upload: true
            },
            // [ckeditorPlugin]: {
            //     options: {
            //         language: "en",
            //         extraAllowedContent: "*{*};*{*}",
            //         allowedContent: true,
            //         extraPlugins: "sharedspace,justify,colorbutton,panelbutton,font",
            //         enterMode: window.CKEDITOR.ENTER_BR,
            //         toolbar: [
            //             { name: "actions", items: ["Undo", "Redo"]},
            //             { name: "styles", items: [ "Format", "Font", "FontSize"]},
            //             [ "Bold", "Italic", "Underline", "Strike", "Subscript", "Superscript", "-", "RemoveFormat" ],
            //     	    { name: "justify", items: ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"]},
            //             { name: "paragraph", items: [ "NumberedList", "BulletedList"]},
            //             { name: "links", items: [ "Link", "Unlink", "Anchor" ] },
            //             { name: "table", items: ["Table"]},
            //             { name: "colors", items: ["TextColor", "BGColor", "Style"]}
            //         ],
            //         removeButtons: null
            //     }
            // }
            },
            canvas: {
                styles: [
                    'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap',
                    'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
                    'https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap',
                    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'
                ]
            }
        });
        window.editor = editor;
        HeaderDefault(editor)
        if (typeId == typeEnumTemplate.Page) {
            TableList(editor);
        }
        const dataByType = await getTemplateByType();
        if (dataByType != null) {
            Template(editor, dataByType.data);
        }

        editor.Panels.getButton('views', 'open-blocks').set('active', true)
     
        const styleManager = editor.StyleManager;
        const fontProperty = styleManager.getProperty('typography', 'font-family');
        fontProperty.addOption({ value: 'Roboto, Arial, sans-serif', name: 'Roboto' })
        fontProperty.addOption({ value: 'Poppins, Arial, sans-serif', name: 'Poppins' })
        fontProperty.addOption({ value: 'Lato, Arial, sans-serif', name: 'Lato' })
        fontProperty.addOption({ value: 'Montserrat, Arial, sans-serif', name: 'Montserrat' })
        styleManager.render();

        editor.on('component:selected', () => {
            editor.Panels.getButton('views', 'open-sm').set('active', true)
        });

        editor.on('component:remove', function(model) { 
            if (localStorage.getItem('gjs-inputGrapes') != 'undefined' && localStorage.getItem('gjs-inputGrapes') != '') {
                let data = JSON.parse(localStorage.getItem('gjs-inputGrapes'));
                let { id, name } = model.attributes.attributes;
                let dataNew = data.filter((item) => item.id != id && item.name != name) || [];
                localStorage.setItem('gjs-inputGrapes', JSON.stringify(dataNew));
            }
            
         });

         editor.on('component:update', function() { 
            let timeReq = parseInt(localStorage.getItem("time-build") || "0");
            if (new Date().getTime() - timeReq < 500) {
                return;
            }
            localStorage.setItem("time-build", new Date().getTime().toString());

            var data = {
                htmlSchool : editor.getHtml(),
                cssSchool: editor.getCss(),
                assetSchool: editor.getJs(),
                componentSchool: JSON.stringify(editor.getComponents()),
                styleSchool: JSON.stringify(editor.getStyle()),
                inputIEPSchool: localStorage.getItem("gjs-inputGrapes")
            };
            window.parent.postMessage({ eventName: 'changeBuilderPage', data : data}, '*');
         });

        editor.Panels.addButton
        ('options',
            [{
            id: 'save-db',
            className: 'fa fa-floppy-o',
            command: 'save-db',
            attributes: {title: 'Save Changes'}
            }]
        );
        // Add the command
        editor.Commands.add
        ('save-db',
        {
            run: async function (editor, sender) {
                sender && sender.set('active'); // turn off the button
                editor.store();
                await onSaveTemplate(editor)
            }
        });
        window.parent.postMessage(
            {
                eventName: 'onLoadIframe',
                data : true
            },
            '*'
        );
    };

    window.addEventListener('message', (e) => { 
        if (e.data.eventName == "triggerSaveData") {
            ref.current = e.data.isPublish;
            const save = document.querySelector(".fa-floppy-o");
            save.click();
        }
    })

    const getDefaultInputGrapes = () => {
        let inputGrapes = JSON.parse(localStorage.getItem("gjs-inputGrapes") || "")
        inputGrapes = inputGrapes.map(i => {
            let value;
            switch (i.type) {
                case "TableMultiColumns":
                    const defaultValue = {}
                    for (const key in i.value?.[0]) {
                        if(key !== "id") defaultValue[key] = ""
                        else defaultValue.id = i.value?.[0]?.id
                    }
                    value = defaultValue.id ? [defaultValue] : []
                    break;
                case "GoalObjectiveMUI":
                    value = {
                        id: stringGenerateRandom(15),
                            startTime : DATE_MIN_VALUE,
                            endTime: DATE_MIN_VALUE,
                            detailGoal: "",
                            objectives : [
                                {
                                    id: stringGenerateRandom(10),
                                    startTime: DATE_MIN_VALUE,
                                    endTime: DATE_MIN_VALUE,
                                    detailObjectives: ""
                                }
                            ]
                    }
                    break;
                case "TableUploadFile":
                    value = []
                    break;
                default:
                    value = `[${i.name}_${i.id}]`
                    break;
            }
            return {
                ...i,
                value
            }
        })
        return inputGrapes
    }
    
    const onSaveTemplate = async (editor) => {
        try {
            if (editor.getHtml() != undefined && editor.getCss() != undefined && 
            editor.getJs() != undefined && editor.getComponents() != undefined && editor.getStyle() != undefined
            && localStorage.getItem("gjs-inputGrapes") != null) {
                var data = {
                    htmlSchool : editor.getHtml(),
                    cssSchool: editor.getCss(),
                    assetSchool: editor.getJs(),
                    componentSchool: JSON.stringify(editor.getComponents()),
                    styleSchool: JSON.stringify(editor.getStyle()),
                    inputIEPSchool: JSON.stringify(getDefaultInputGrapes()),
                    isPublish: ref.current
                };
                await updateHTMLIEP(domain, templateId, data)
            }
        } catch (err) {
        }
    }


    const getHTMLTemplate = async () => {
        try {
            const res = await getHTMLIEP(domain, templateId);
            return res.data;
        } catch (err) {
        }
    }
    
    const getTemplateByType = async () => {
        try {
            const res = await getTemplateIEPByType(domain, templateId, typeId);
            return {
               typeId: typeId,
               data: res.data
            }
        } catch (err) {
        }
    }

    const runSaveDraft = () => {
        const save = document.querySelector(".fa-floppy-o");
        save?.click();
    }

    useEffect(() => {
        loadGrapesJs();
        if (!!intervalRef.current) clearInterval(intervalRef.current)
            intervalRef.current = setInterval(runSaveDraft, ONE_MINUTE_MILLISECONDS)
    }, []);
    
    return  {
        onSaveTemplate,
        getHTMLTemplate,
        getTemplateByType
    }
}

export default useGrapesjsMain;