import "grapesjs/dist/css/grapes.min.css";
import MuiComponents from "../../components/mui-components";

const ConfigGrapesView = () => {
  return {
    // Indicate where to init the editor. You can also pass an HTMLElement
    container: "#gjs",
    height: "100vh",
    width: "auto",
    plugins: [MuiComponents]
  }
};

export default ConfigGrapesView;
