import Grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getHTMLIEP, getLearningPlanById, getTemplateIEPByType } from "../services"
import Template from "../components/Template/index"
import ConfigGrapesView from "../containers/BuilderPage/ConfigGrapesView";
import "../components/app.css";
import { toTextHtmlContent } from "../components/script";
import { utcToLocalTime } from "../utils";
import { GRADES_STRING } from "../config/constants";

const useLearningPlanIEP = () => {
    const { templateId } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const domain = urlParams.get('domain');
    const typeId = urlParams.get('typeId');
    const learningPlanId = urlParams.get('learningPlanId');
    const studentName = urlParams.get('studentName');
    const gradeName = urlParams.get('gradeName');

    const loadGrapesJs = async () => {
        const data = await getHTMLTemplate();
        if (data != null) {
            let newData = data.inputIEPSchool;
            let newHTML = data.htmlSchool
                .replaceAll("$STUDENT_NAME$", studentName)
            let newComponent = data.componentSchool
                .replaceAll("$STUDENT_NAME$", studentName)
            let newCss = data.cssSchool;
            let newStyle = data.styleSchool;
            let newAsset = data.assetSchool;
            
            if (!!parseInt(learningPlanId)) {
                const res = await getLearningPlanById(domain, parseInt(learningPlanId));
                const data1 = res.data;

                const { gradeLevel, createdAt } = data1
                const studentGrade = gradeLevel ? GRADES_STRING[gradeLevel] : null
                const createdAtFormat = createdAt ? utcToLocalTime(createdAt, "D/M/YY hh:mm a") : ""
                newHTML = newHTML
                    .replaceAll("$GRADE$", studentGrade || gradeName)
                    .replaceAll("$CREATED_DATE$", createdAtFormat);
                newComponent = newComponent
                    .replaceAll("$GRADE$", studentGrade || gradeName)
                    .replaceAll("$CREATED_DATE$", createdAtFormat);
                
                const inputIEP = JSON.parse(data1?.dataLearningPlanIEP);
                inputIEP.forEach((record) => {
                    if (record.value != "") {
                        const searchText = `[${record.name}_${record.id}]`;
                        const valueText = Array.isArray(record.value) ? `${JSON.stringify(record.value)}` : record.value
                                                    ?.replaceAll("\\", "\\\\").replaceAll("\b", "\\b").replaceAll("\f", "\\f")
                                                    .replaceAll("\n", "\\n").replaceAll("\r", "\\r")
                                                    .replaceAll("\f", "\\f").replaceAll("\t", "\\t")
                                                    .replaceAll('\"', '\\"');
                        newHTML = newHTML.replace(searchText, valueText);
                        if (Array.isArray(record.value)) {
                            let searchNew = `"[${record.name}_${record.id}]"`;
                            newComponent = newComponent.replace(searchNew, valueText);      
                        } else {
                            newComponent = newComponent.replace(searchText, valueText);
                        }
                    }
                });
                newData = data1.dataLearningPlanIEP;
                window.parent.postMessage({ eventName: 'marioInputDataIEP',  dataLearningPlanIEP: data1?.dataLearningPlanIEP}, '*');
            }else{
                newHTML = newHTML
                    .replaceAll("$GRADE$", gradeName)
                    .replaceAll("$CREATED_DATE$", "");
                newComponent = newComponent
                    .replaceAll("$GRADE$", gradeName)
                    .replaceAll("$CREATED_DATE$", "");
            }
            localStorage.setItem("gjs-html", newHTML)
            localStorage.setItem("gjs-css", newCss)
            localStorage.setItem("gjs-components", newComponent)
            localStorage.setItem("gjs-styles", newStyle)
            localStorage.setItem("gjs-assets", newAsset)
            localStorage.setItem("gjs-inputGrapes", newData)
            window.parent.postMessage({ eventName: 'marioChange', data : newData}, '*');
            window.parent.postMessage({
                eventName: 'onReceiveContent',
                data : toTextHtmlContent(
                        newCss,
                        newHTML,
                        newData
                    )
                },
                '*'
            );
        }
        
        const editor = await Grapesjs.init(ConfigGrapesView());
        window.editor = editor;
        editor.Css.setRule(".required::after", {content: '" *"', color: "red"})
        const dataByType = await getTemplateByType();
        if (dataByType != null) {
            Template(editor, dataByType.data);
        }

        // Execute a callback on all inner components starting from the root
        editor.DomComponents.getWrapper().onAll(comp => {
            comp.set({ editable: false, selectable:false, hoverable:false});
        }
        );
        const iframe = document.querySelector(".gjs-frame");
        var htmlResult = new XMLSerializer().serializeToString(iframe.contentDocument || iframe.contentWindow.document);
        window.parent.postMessage({ eventName: 'marioIEPHTML', htmlResult : htmlResult}, '*');
    };
    
    const getHTMLTemplate = async () => {
        try {
            const res = await getHTMLIEP(domain, templateId);
            return res.data;
        } catch (err) {
        }
    }

    const getTemplateByType = async () => {
        try {
            const res = await getTemplateIEPByType(domain, templateId, typeId);
            return {
               typeId: typeId,
               data: res.data
            }
        } catch (err) {
        }
    }

    useEffect(async () => {
        await loadGrapesJs();
        const preview = document.querySelector(".gjs-pn-buttons span.fa-eye");
        preview.click();
        // console.log({ preview })
        window.parent.postMessage(
            {
                eventName: 'onLoadIframe',
                data : true
            },
            '*'
        );
    }, [learningPlanId]);
 
    return  {
        getHTMLTemplate
    }
}

export default useLearningPlanIEP;