export const getFnToTableMultiColumnHtml = (
  functionName = "toTableMultiColumnHtml"
) => {
  return `const ${functionName} = ({ columns, value }) => {
    return \`
      <table class="MuiTextFieldTable-customers">
          <thead>
              <tr>
                  \${columns.map((i) => \`<th>\${i}</th>\`).join("")}
              </tr>
          </thead>
          <tbody>
              \${value
                .map(
                  (i) => \`
                      <tr>
                          \${columns
                            .map(
                              (c) => \`
                              <td>
                                  \${i[c]}    
                              </td>    
                          \`
                            )
                            .join("")}
                      </tr>
                  \`
                )
                .join("")}
          </tbody>
      </table>
    \`;
  };`;
};

export const toTableMultiColumnHtml = (
  functionName = "toTableMultiColumnHtml",
  variablesName
) => {
  return `
        const ${variablesName} = document.querySelectorAll("TableMultipleColumns");
        tableMultiColumns?.forEach((el) => {
            const id = el.getAttribute("id");
            const columnsString = el.getAttribute("columns") || "";
            const columns = columnsString?.split("|") || [];
            const value = data?.find((i) => i.id === id);
            const defaultValue = {};
            columns.forEach((i) => (defaultValue[i] = ""));
            const htmlContent = ${functionName}?.({
            columns,
            value:
                value?.value === \`[\${value?.name}_\${value?.id}]\`
                ? [defaultValue]
                : value?.value || [defaultValue],
            });
            const newItem = document.createElement("div");
            newItem.innerHTML = htmlContent;
            el?.parentElement.replaceChild(newItem, el);
        });
    `;
};
