import React, { useMemo, useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { makeStyles } from "@material-ui/core/styles";
import { writeDataChange, getTypeItemData } from "../../utils/index"

const useStyles = makeStyles({
  checkboxBlue: {
    "&": {
      marginRight: "1rem",
      paddingLeft: "1.75rem",
      position: "relative",
      marginTop: "1rem",
      marginBottom: "1rem"
    },
  },
  checkboxBlueReverse:{
    "&": {
      marginLeft: "1rem",
      paddingRight: "1.75rem",
      marginRight: 0,
      paddingLeft: 0
    }
  },
  InputCheckBoxReverse: {
    "&" : {
      left: "unset",
      right: 0
    }
  },
  InputCheckBox: {
    "&" : {
      position: "absolute",
      opacity: "0",
      left: "0"
    },
    "&:focus ~ span": {
      border: "2px solid #aab0b9"
    },
    "&:focus:checked ~ span": {
      border: "2px solid #265b81"
    },
    "&:checked ~ span" : {
      color: "#FFFFFF",
      background: "#3785BC url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+) 50% 40% no-repeat",
      border: "2px solid #3785BC"
    }
  },
  Indicator: {
    borderRadius: "3px",
    position: "absolute",
    left: "0",
    top: "0px",
    width: "18px",
    height: "18px",
    backgroundColor: "#ffffff",
    border: "2px solid #d4d7dc",
    pointerEvents: "none"
  },
  IndicatorReverse: {
    left: "unset",
    right: "0"
  },
  TextBold: {
    fontWeight: "bold"
  },
  alignRight: {
    justifyContent: "end"
  },
  alignCenter: {
    justifyContent: "center"
  }
}, { name: 'MuiCheckBox' });

const CheckboxMui = (props) => {
  const { name, options, value, required, id, label, direction, align, reverse, bold } = props;
  const [data, setData] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (value == `[${name}_${id}]`) {
      setData([])
    } else {
      setData(value);
    }
  }, [value])

  const handleChange = (value) => {
    let dataNew = [];
    let dataOld = getTypeItemData(id, 'CheckboxMui');
    if (dataOld == `[${name}_${id}]` || !dataOld) {
      dataNew = [value];
    } else {
      if (dataOld.includes(value)) {
        dataNew = dataOld.filter((item) => item != value);
      } else {
        dataNew = [...dataOld, value]
      }
    }
    
    setData(JSON.parse(JSON.stringify(dataNew)))
    let obj = {
      id: id,
      name: name,
      label: label,
      required: required,
      value: dataNew,
      type: 'CheckboxMui'
    }
    writeDataChange(obj)
    let data = localStorage.getItem("gjs-inputGrapes");
    window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
  }

  useEffect(() => {
    let obj = {
      id: id,
      name: name,
      label: label,
      required: required,
      value: !!value ? value : [],
      type: 'CheckboxMui'
    }
    writeDataChange(obj)
  }, [name, required, label])

  const listOptions = useMemo(() => {
    let arrNew = {};
    options?.split("|").forEach((item) => {
        if (item.trim() !== "" && item.includes(":")) {
            let indexArr = item.trim()?.split(":");
            arrNew[indexArr[0]] = indexArr[1];
        }
    });
    return arrNew;
  }, [options])

  const alignClass = useMemo(() => (align === 'Right' ? classes.alignRight : align === 'Center' ? classes.alignCenter : ""), [align])
  console.log("run")
  return (
      <FormGroup row={direction == 'row'} className={alignClass}>
        {
            !!listOptions && Object.keys(listOptions).length > 0 && Object.keys(listOptions)?.map((key) => 
                (
                  <label key={key} className={`${classes.checkboxBlue} ${reverse ? classes.checkboxBlueReverse : ""} ${!!bold ? classes.TextBold : ""}`}>
                    <input type='checkbox' className={`${classes.InputCheckBox} ${reverse ? classes.InputCheckBoxReverse : ""}`} checked={data.includes(key)} onChange={() => handleChange(key)} name={name} />
                      <span className={`${classes.Indicator} ${reverse ? classes.IndicatorReverse : ""}`}></span>
                      {listOptions[key]}
                  </label>
                ))
        }
      </FormGroup>
      
  );
}

export default CheckboxMui;