import TextField from './TextFieldMui';
import { stringGenerateRandom } from "../../utils"

const index = ({ editor, model, view }) => {
  const idString = stringGenerateRandom(15);

  editor.BlockManager.add("TextFieldMui", {
    label: "<div class='gjs-fonts gjs-f-b1'>TextField</div>",
    category: 'Form MUI',
    content: `<TextFieldMUI label="Outlined" variant="outlined" multiline name="outlined" rows="" value="[outlined_${idString}]" id=${idString} />`
  });

  editor.DomComponents.addType("TextFieldMUI", {
    model: {
      ...model,
      defaults: {
        component: TextField,
        stylable: true,
        editable: true,
        void: false,
        droppable: false,
        attributes: {
          label: "Outlined",
          variant: "outlined"
        },
        traits: [
          {
            type: "text",
            label: "Id",
            name: "id"
          },
          {
            type: "text",
            label: "Name",
            name: "name"
          },
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          {
            type: "text",
            label: "Label",
            name: "label"
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          {
            type: "checkbox",
            label: "Multiline",
            name: "multiline",
          },
          {
            type: "text",
            label: "Rows",
            name: "rows"
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
          },
          {
            type: "select",
            label: "FullWidth",
            name: "fullWidth",
            options: [ // Array of options
                { id: '100%', name: 'FullWidth'},
                { id: 'auto', name: 'Auto'},
              ]
          } 
        ]
      }
    },
    view,
    isComponent: el => el.tagName === "TEXTFIELDMUI"
  });
}
export default index;