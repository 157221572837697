import React from "react";
import useGrapesjsView from "../../hooks/useGrapesjsView";

const GrapesjsView = () => {
  const { getHTMLTemplate } = useGrapesjsView();

  return (
    <div id="result-template">
      <div id="gjs">
      </div>
    </div>
      
  );
};

export default GrapesjsView;
