export const tableStyle = `
  .MuiTextFieldTable-customers {
    width: 100%;
  }
  .MuiTextFieldTable-customers th {
    color: white;
    border-top: none;
    text-align: left;
    border-left: none;
    padding-top: 8px;
    border-right: 1px solid #ddd;
    padding-left: 10px;
    border-bottom: none;
    padding-bottom: 8px;
    background-color: #67afef;
  }
  .MuiTextFieldTable-customers td {
    padding: 10px 10px 0;
  }
  .MuiTextFieldFile-textLink {
    color: #3c94ff;
    cursor: pointer;
    text-decoration: underline;
  }
`;
