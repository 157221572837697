import { convertCategoryNameByType } from "../../utils";

const index = (editor, data) => {
    data.forEach(element => {
        editor.BlockManager.add(`${element.nameTemplate}`, {
            label: `<div class='gjs-fonts gjs-f-b1'> ${element.nameTemplate}</div>`,
            category: convertCategoryNameByType(element?.type),
            content: `${element.htmlSchool}
                <style>
                    ${element.cssSchool}
                </style>`,
            })
    });
  }
  export default index;