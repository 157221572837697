import React, { useState, useMemo, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { writeDataChange } from "../../utils/index"

const useStyles = makeStyles({
    formControl: {
      margin: "8px",
      width: "100%",
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px !important"
      }
    },
    selectEmpty: {
      marginTop: "16px",
    },
},  { name: 'MuiSelect' });

const SelectMui = (props) => {
    const { options, label, disabled, required, name, value, id } = props;
  
    const classes = useStyles();
    const [option, setOption] = useState("")
    const handleChange = (event) => {
        setOption(event.target.value)
        let obj = {
          id: id,
          name: name,
          label: label,
          required: required,
          value: event.target.value,
          type: 'SelectMui'
        }
        writeDataChange(obj)
        let data = localStorage.getItem("gjs-inputGrapes");
        window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
    };
    
    const listOptions = useMemo(() => {
        let arrNew = {};
        options?.split("|").forEach((item) => {
            if (item.trim() !== "" && item.includes(":")) {
                let indexArr = item.trim()?.split(":");
                arrNew[indexArr[0]] = indexArr[1];
            }
        });
        return arrNew;
    }, [options])

    const isCheckError = useMemo(() => {
        if (required) {
            if (option.length > 0) {
              return false;
            }
            return true;
        }
        return false;
    }, [option, required])

    useEffect(() => {
      if (value == `[${name}_${id}]`) {
        setOption("")
      } else {
        setOption(value);
      }
    }, [value])

    useEffect(() => {
      let obj = {
        id: id,
        name: name,
        label: label,
        value: value,
        required: required,
        type: 'SelectMui'
      }
      writeDataChange(obj)
    }, [name, label, required])

    return (
        <FormControl variant="outlined" className={classes.formControl} error={isCheckError} disabled={disabled} required={required}>
        <InputLabel htmlFor="outlined-age-native-simple">{label}</InputLabel>
        <Select
          native
          value={option}
          onChange={handleChange}
          label={label}
          inputProps={{
            name: {label},
            id: 'outlined-age-native-simple',
          }}
        >
           <option value={""}></option>
          {
            !!listOptions && Object.keys(listOptions).length > 0 && Object.keys(listOptions)?.map((key) => 
                (
                    <option value={key} key={key}>{listOptions[key]}</option>
                )
            )
          }
        </Select>
      </FormControl>
    )
}

export default SelectMui;