import { createReducer } from "@reduxjs/toolkit"

import {
    setDomain
} from "./action"

const initialState = {
    domain: "localhost"
}

const commonReducer = createReducer(initialState, builder => {
    builder
        .addCase(setDomain, (state, action) => {
            state.domain = action.payload
        })
})

export default commonReducer
