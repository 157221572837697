export const getFnToCheckboxHtml = (functionName = 'toCheckboxHtml') => {
    return `const ${functionName} = ({ name,  options = [], value = [], bold, reverse, align }) => {
        return \`
            <div class="MuiFormGroup-root MuiFormGroup-row MuiCheckBox-align\${align}">
                \${
                    options.map(i => \`
                        <label class="MuiCheckBox-checkboxBlue \${reverse ? 'MuiCheckBox-checkboxBlueReverse' : ''} \${bold ? 'MuiCheckBox-TextBold' : ''}">
                            <span class="MuiCheckBox-Indicator \${reverse ? 'MuiCheckBox-IndicatorReverse' : ''}" style="\${value?.find?.(v => v === i.value) ? 'background-color: #3785BC' : ''}"></span>
                            \${i.label}
                        </label>
                    \`).join('\\n')
                }
            </div>
        \`;
    }`
}

export const toCheckboxHtml = (functionName = 'toCheckboxHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('CheckboxMui')
        ${variablesName}?.forEach(el => {
            const id = el.getAttribute('id')
            const bold = el.getAttribute('bold') == ''
            const reverse = el.getAttribute('reverse') == ''
            const align = el.getAttribute('align')
            let options = el.getAttribute('options') || ''
            options = options.split('|').map(i => {
                const splitVal = i.split(':')
                return {
                    label: splitVal?.[1]?.trim() || '',
                    value: splitVal?.[0]?.trim() || ''
                }
            })
            const value = data?.find(i => i.id === id)
            const htmlContent = ${functionName}?.({name: value?.name, options, bold, reverse, align, value: value?.value === \`[\${value?.name}_\${value?.id}]\` ? [] : value?.value})
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            el?.parentElement.replaceChild(newItem, el)
        })
    `
}