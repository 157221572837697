export const getFnToFileUploadHtml = (functionName = 'toFileUploadHtml') => {
    return `const ${functionName} = ({ value = [] }) => {
        return \`
                <div>
                    <div class="button-upload">
                        Upload File
                    </div>
                    <ul class="MuiList-root list-version MuiList-padding">
                        \${
                            value?.map(i => \`
                                        <li class="MuiListItem-container">
                                            <div
                                                class="MuiListItem-root item-version MuiListItem-gutters MuiListItem-secondaryAction">
                                                <div class="MuiListItemIcon-root">
                                                    <svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" style="width: 24px; height: 24px;"
                                                        aria-hidden="true">
                                                        <path
                                                            fill="#999"
                                                            d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z">
                                                        </path>
                                                    </svg>
                                                </div>
                                                <div class="MuiListItemText-root item-version-text MuiListItemText-multiline">
                                                    <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">
                                                        \${i.nameFile}
                                                    </span>
                                                    <p class="MuiTypography-root MuiListItemText-secondary MuiTypography-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">
                                                        \${i.typeFile}
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="MuiListItemSecondaryAction-root">
                                            </div>
                                        </li>
                            \`)
                        }
                    </ul>
                </div>
            \`;
    }`
}

export const toFileUploadHtml = (functionName = 'toFileUploadHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('FileUploadMUI')
        ${variablesName}.forEach(el => {
            const id = el.getAttribute('id')
            const value = data?.find(i => i.id === id)
            const htmlContent = ${functionName}?.({ value: value?.value === \`[\${value?.name}_\${value?.id}]\` ? [] : value?.value })
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            el?.parentElement.replaceChild(newItem, el)
        })
    `
}