import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, InputAdornment } from '@material-ui/core';
import { writeDataChange, getTypeItemData } from "../../utils"

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important",
        minHeight: "55px"
    },
  },
  point: {
    "&": {
      cursor: "pointer"
    }
  }
}, { name: "MuiTextField" });

const TextFieldMui = (props) => {
  const [valueTextField, setValueTextField] = useState("");
  const [isCheck, setIsCheck] = useState(false)
  const classes = useStyles();
  const { name, label, id, disabled, required, value, fullWidth, multiline, rows } = props;
  const [isRecording, setIsRecording] = useState(false);
  const isCheckError = useMemo(() => {
      if (required) {
          if (valueTextField.length > 0) {
            return false;
          }
          return true;
      }
      return false;
  }, [valueTextField, required])

  const valueInputText = useMemo(() => {
    const item = getTypeItemData(id, "TextFieldMUI");
    if (item === `[${name}_${id}]`) {
      return "";
    }
    return item;

  }, [valueTextField, id])

  const onClickRecord = (id) => {
    let timeReq = parseInt(localStorage.getItem("time-input") || "0");
    if (new Date().getTime() - timeReq < 500) {
        return;
    }
    localStorage.setItem("time-input", new Date().getTime().toString());
    let obj = {
      id: id,
      name: name,
      label: label,
      type: 'TextFieldMUI'
    }
    localStorage.setItem("currentChangeField", JSON.stringify(obj))
    window.parent.postMessage({ eventName: 'startAudioRecord', isStartRecord: true}, '*');
  }
  const stopRecord = () => {
    setIsRecording(false)
    window.parent.postMessage({ eventName: 'stopAudioRecord', isStopRecord: true}, '*');
  }

  window.addEventListener('message', function(event) {
    if (event.data.eventName == 'parentSendStartRecord') {
      let currentInput = JSON.parse(localStorage.getItem("currentChangeField"));
        if (currentInput.id == id) {
          let isStartRecord = event.data.isRecording;
          isStartRecord && setIsRecording(isStartRecord);
        }
    }

    if (event.data.eventName === 'parentSendDataRecord') {
      const valueText = event.data.dataInput;
      let currentField = JSON.parse(localStorage.getItem("currentChangeField"));
      let obj = {
        id: currentField.id,
        name: currentField.name,
        label: currentField.label,
        value: valueText,
        type: 'TextFieldMUI',
        required
      }
      writeDataChange(obj)
      setValueTextField(valueText)
      let data = localStorage.getItem("gjs-inputGrapes");
      window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
    }
  })

  useEffect(() => {
    if (value == `[${name}_${id}]`) {
      setValueTextField("")
    } else {
      setValueTextField(value);
    }
  }, [value])
  

  const handleChange = (event) => {
    setValueTextField(event.target.value);
    let obj = {
      id: id,
      name: name,
      label: label,
      value: event.target.value,
      type: 'TextFieldMUI',
      required
    }
    writeDataChange(obj)
    if (event.target.value.length == 0) {
      setIsCheck(true)
    }
    let data = localStorage.getItem("gjs-inputGrapes");
    window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
  }

  useEffect(() => {
    let obj = {
      id: id,
      name: name,
      label: label,
      required: required,
      value: value,
      type: 'TextFieldMUI'
    }
    writeDataChange(obj)
  }, [name, label, required, value])
  return (
    <div className="text-field">
      <TextField
          id={id}
          label={label}
          name={`${name}_${id}`}
          variant="outlined"
          disabled={disabled}
          required={required}
          value={valueInputText}
          multiline={multiline}
          rows={multiline ? rows : -1}
          error={isCheckError && isCheck}
          onChange={handleChange}
          className={classes.root}
          style={{ width: `${fullWidth}` }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                  {isRecording ? (
                      <img
                          className={classes.point}
                          src="/images/recording-dot.png"
                          onClick={() => {
                              !disabled && stopRecord(id)
                          }}
                      />
                  ) : (
                      <img
                          className={classes.point}
                          src="/images/icon-micro.png"
                          onClick={() => {
                              !disabled && onClickRecord(id)
                          }}
                      />
                  )}
              </InputAdornment>
            )
          }}
      />
    </div>

  );
}

export default TextFieldMui;