export const SelectMuiStyle = `
    .select-mui{
        position: relative;
    }
    .select-mui::after{
        content: '';
        display: inline-block;
        border: 6px solid transparent;
        border-top-color: #999;
        position: absolute;
        top: 50%;
        right: 14px;
        transform: translateY(-50%);
    }
`