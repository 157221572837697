export const getFnToAvatarImageHtml = (functionName = "toAvatarImageHtml") => {
  return `const ${functionName} = (value = "") => {
        return \`
            <div class="wrapper-item">
                \${
                    value ?
                    \`
                        <img
                            class="file-img"
                            src="\${value}"
                            alt="upload-img"
                        />
                    \`
                    :
                    \`
                    <div class="file-wrapper upload-avatar">
                        <img
                            class="no-file-img"
                            src="/images/uploadfile.png"
                            alt="upload-img"
                        />
                        <h4 class="upload-image">Avatar Image</h4>
                    </div>
                    \`
                }
            </div>
        \`;
    }`;
};

export const toAvatarImageHtml = (functionName = "toAvatarImageHtml", variablesName) => {
  return `
        const ${variablesName} = document.querySelectorAll('AvatarImageMui')
        ${variablesName}?.forEach(el => {
            const id = el.getAttribute('id')
            const value = data?.find(i => i.id === id)
            const htmlContent = ${functionName}?.(value?.value === \`[\${value?.name}_\${value?.id}]\` ? '' : value?.value)
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            el?.parentElement.replaceChild(newItem, el)
        })
    `;
};
