import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { routerIndex } from "./routes"

function App() {
  return (
    <div className="App">
      {/* <GrapesjsMain/> */}
      <BrowserRouter>
        <Switch>
          {
            !!routerIndex && routerIndex.length > 0 && routerIndex.map((item) => (
              <Route path={item.path} exact={true} key={item.id}>
                {item.component}
              </Route>
            ))
          }
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
