export const GoalStyle = `
    .MuiGoal-boxGoal {
        margin-top: 15px;
        width: 80%;
    }

    .MuiGoal-grid {
        display: block;
        position: relative;
    }

    .MuiGoal-objectives {
        display: flex;
        margin-bottom: 15px;
    }
    .MuiGoal-objectives > div {
        width: 33.333333%;
    }
    .MuiGoal-gridNext {
        display: block;
        position: relative;
        margin-top: 15px;
        padding-left: 40px;
    }
`