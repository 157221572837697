
import TableMultiColumns from "./TableMultiColumns"
import TableUploadFile from "./TableUploadFile"
import { stringGenerateRandom } from "../../utils"
  
const tableIndex = ({ editor, model, view }) => {
  const idString = stringGenerateRandom(15);
    
  editor.BlockManager.add("TableMultipleColumns", {
    label: "<div class='gjs-fonts gjs-f-b1'>Table Multiple Columns</div>",
    category: 'Table Default',
    content: `<TableMultipleColumns columns="column1|column2" name="multiple_columns" value="[multiple_columns_${idString}]" id="${idString}" />`
  });

  editor.DomComponents.addType("TableMultipleColumns", {
    model: {
      ...model,
      defaults: {
        component: TableMultiColumns,
        stylable: true,
        editable: true,
        void: true,
        droppable: false,
        traits: [
          {
            type: "text",
            label: "Id",
            name: "id"
          },
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          {
            type: "text",
            label: "Name",
            name: "name",
          },
          {
              type: "text",
              label: "Columns",
              name: "columns",
          }
        ]
      }
    },
    view,
    isComponent: el => el.tagName === "TABLEMULTIPLECOLUMNS"
  });

  editor.BlockManager.add("TableUploadFile", {
    label: "<div class='gjs-fonts gjs-f-b1'>Table UploadFile</div>",
    category: 'Table Default',
    content: `<TableUploadFile label="TableUploadFile" variant="table_uploaded" name="table_uploaded" value="[table_uploaded_${idString}]" id=${idString} />
        <style>
        .wrapper-item {
            width: 100%;
          }
          #file-upload {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
          }
          .file-title {
            margin: 10px 0;
          }
          .required-icon {
            color: red;
          }
          .file-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px dashed rgba(0, 0, 0, 0.54);
            border-radius: 12px;
            cursor: pointer;
            width: 100%;
            height: 175px;
            position: relative;
          }
          .no-file-img {
            width: 62px;
          }
          .upload-image {
            margin: 0;
            line-height: 1.5;
            font-size: 16px;
            letter-spacing: 0.00938em;
            font-weight: 400;
          }
          .type-file {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.54);
            margin: 0;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            font-weight: 400;
          }
          
          .file-uploaded-img {
            width: 62px;
            height: 62px;
          }
        </style>
    `
  });

  editor.DomComponents.addType("TableUploadFile", {
    model: {
        ...model,
      defaults: {
        component: TableUploadFile,
        stylable: true,
        editable: true,
        void: true,
        droppable: false,
        traits: [
          {
            type: "text",
            label: "Id",
            name: "id"
          },
          {
            type: "text",
            label: "Name",
            name: "name"
          },
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          {
            type: "text",
            label: "Label",
            name: "label"
          }
        ]
      }
    },
    view,
    isComponent: el => el.tagName === "TABLEUPLOADFILE"
  });

}
export default tableIndex;