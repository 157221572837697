export const getFnToImageMuiHtml = (functionName = 'toImageMuiHtml') => {
    return `const ${functionName} = ({ value, label, required }) => {
        return \`
            <div class="wrapper-item">
                <h3 class="file-title">\${label} \${required ? '<i class="required-icon">*</i>' : ''}</h3>
                <div class="file-wrapper">
                    <img class="no-file-img" src="\${value || ''}" alt="upload-img">
                    <h4 class="upload-image">Upload Image</h4>
                    <h5 class="type-file">Supported image types: PNG, JPEG, WEBP</h5>
                </div>
            </div>
        \`;
    }`
}

export const toImageMuiHtml = (functionName = 'toImageMuiHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('ImageMUI')
        ${variablesName}.forEach(el => {
            const value = el.getAttribute('value')
            const label = el.getAttribute('label')
            const name = el.getAttribute('name')
            const id = el.getAttribute('id')
            const required = el.getAttribute('required')
            const htmlContent = ${functionName}?.({ label, value: value === \`[\${name}_\${id}]\` ? '' : value, required })
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            el?.parentElement.replaceChild(newItem, el)
        })
    `
}