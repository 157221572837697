import SelectMui from "./SelectMui"
import { stringGenerateRandom } from "../../utils"

const index = ({ editor, model, view }) => {
  const idString = stringGenerateRandom(15);

    editor.BlockManager.add("SelectMui", {
      label: "<div class='gjs-fonts gjs-f-b1'>Select</div>",
      category: 'Form MUI',
      content: `<SelectMui label="label option" name="outlined" value="[outlined_${idString}]" direction="row" id="${idString}" />`
    });
  
    editor.DomComponents.addType("SelectMui", {
      model: {
        ...model,
        defaults: {
          component: SelectMui,
          stylable: true,
          editable: true,
          void: true,
          droppable: false,
          traits: [
            {
              type: "text",
              label: "Id",
              name: "id"
            },
            {
              type: "text",
              label: "Value",
              name: "value",
            },
            {
                type: "text",
                label: "Name",
                name: "name"
            },
            {
                type: "text",
                label: "Label",
                name: "label"
            },
            {
                type: "checkbox",
                label: "Disabled",
                name: "disabled",
            },
            {
                type: "text",
                label: "Options",
                name: "options"
            },
            {
                type: "checkbox",
                label: "Required",
                name: "required",
            } 
          ]
        }
      },
      view,
      isComponent: el => el.tagName === "SELECTMUI"
    });
  }
  export default index;