import AvatarImageMUI from './AvatarImageMUI';
import { stringGenerateRandom } from "../../utils"

const index = ({editor, model, view }) => {
  const idString = stringGenerateRandom(15);
  editor.BlockManager.add("AvatarImageMUI", {
    label: "<div class='gjs-fonts gjs-f-b1'>Avatar Image MUI</div>",
    category: 'Form MUI',
    content: `<AvatarImageMUI label="Avatar" variant="outlined" name="avatar" value="[avatar_${idString}]" id=${idString} />
        <style>
          .wrapper-item {
            width: 100%;
          }
          .wrapper-item.wrapper-box .upload-avatar {
            border: unset !important;
          }
          #file-upload {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
          }
          .file-title {
            margin: 10px 0;
          }
          .required-icon {
            color: red;
          }
          .file-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px dashed rgba(0, 0, 0, 0.54);
            border-radius: 12px;
            cursor: pointer;
            width: 100%;
            height: 175px;
            position: relative;
          }
          .file-wrapper.upload-avatar img.no-file-img{
            width: 50% !important;
          }

          .file-wrapper.upload-avatar img.file-img{
            width: 100% !important;
          }
          
          .file-wrapper.upload-avatar  .upload-image {
            margin: 0;
            line-height: 1.5;
            font-size: 16px;
            letter-spacing: 0.00938em;
            font-weight: 400;
            margin-top: 15px;
          }
          .type-file {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.54);
            margin: 0;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            font-weight: 400;
          }
          
          .file-uploaded-img {
            width: 62px;
            height: 62px;
          }
        </style>
    `
  });

  editor.DomComponents.addType("AvatarImageMUI", {
    model: {
        ...model,
      defaults: {
        component: AvatarImageMUI,
        stylable: true,
        editable: true,
        void: true,
        droppable: false,
        attributes: {
          label: "avatar",
          variant: "avatar"
        },
        traits: [
          {
            type: "text",
            label: "Id",
            name: "id"
          },
          {
            type: "text",
            label: "Name",
            name: "name"
          },
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          {
            type: "text",
            label: "Label",
            name: "label"
          }
        ]
      }
    },
    view,
    isComponent: el => el.tagName === "AVATARIMAGEMUI"
  });
}
export default index;