import ImageMUI from './ImageMui';
import { stringGenerateRandom } from "../../utils"

const index = ({editor, model, view }) => {
  const idString = stringGenerateRandom(15);
  editor.BlockManager.add("ImageMUI", {
    label: "<div class='gjs-fonts gjs-f-b1'>ImageMUI</div>",
    category: 'Form MUI',
    content: `<ImageMUI label="Outlined" variant="outlined" name="outlined" value="[outlined_${idString}]" id=${idString} />
        <style>
        .wrapper-item {
            width: 100%;
          }
          #file-upload {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
            opacity: 0;
            cursor: pointer;
          }
          .file-title {
            margin: 10px 0;
          }
          .required-icon {
            color: red;
          }
          .file-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px dashed rgba(0, 0, 0, 0.54);
            border-radius: 12px;
            cursor: pointer;
            width: 100%;
            height: 175px;
            position: relative;
          }
          .no-file-img {
            width: 62px;
          }
          .upload-image {
            margin: 0;
            line-height: 1.5;
            font-size: 16px;
            letter-spacing: 0.00938em;
            font-weight: 400;
          }
          .type-file {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.54);
            margin: 0;
            line-height: 1.5;
            letter-spacing: 0.00938em;
            font-weight: 400;
          }
          
          .file-uploaded-img {
            width: 62px;
            height: 62px;
          }
        </style>
    `
  });

  editor.DomComponents.addType("ImageMUI", {
    model: {
        ...model,
      defaults: {
        component: ImageMUI,
        stylable: true,
        editable: true,
        void: true,
        droppable: false,
        attributes: {
          label: "Outlined",
          variant: "outlined"
        },
        traits: [
          {
            type: "text",
            label: "Id",
            name: "id"
          },
          {
            type: "text",
            label: "Name",
            name: "name"
          },
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          {
            type: "text",
            label: "Label",
            name: "label"
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
          } 
        ]
      }
    },
    view,
    isComponent: el => el.tagName === "IMAGEMUI"
  });
}
export default index;