export const getFnToSelectMuiHtml = (functionName = 'toSelectMuiHtml') => {
    return `const ${functionName} = ({ id, label, name, value, required, fullscreen = true }) => {
        return \`
            <div class="text-field select-mui" style="margin-bottom: 8px">
                <div class="MuiFormControl-root MuiTextField-root MuiTextField-root MuiFormControl-marginNormal" style="\${fullscreen ? 'width: 100%;' : ''}">
                    <label
                        class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated \${!!value ? ' MuiInputLabel-shrink MuiFormLabel-filled' : ''} \${required ? 'Mui-required' : ''} MuiInputLabel-outlined Mui-required Mui-required"
                        data-shrink="true" for="\${id}" id="\${id}-label"
                    >
                        \${label}
                        \${required
                                ? '<span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>'
                                : ""
                            }
                    </label>
                    <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                        <div class="MuiInputBase-input MuiOutlinedInput-input" style="height: auto;\${!value ? 'color: #e5e5e5;' : ''}">\${value || label}</div>
                        <fieldset aria-hidden="true"
                            class="PrivateNotchedOutline-root-1 MuiOutlinedInput-notchedOutline">
                            <legend
                            class="PrivateNotchedOutline-legendLabelled-3 \${!!value ? 'PrivateNotchedOutline-legendNotched-4' : ''}">
                            <span>\${label}\${required ? "&nbsp;*" : ""}</span>
                            </legend>
                        </fieldset>
                    </div>
                </div>
            </div>
        \`;
    };`
}

export const toSelectMuiHtml = (functionName = 'toSelectMuiHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('SelectMui')
        ${variablesName}?.forEach(el => {
            const id = el.getAttribute('id')
            let options = el.getAttribute('options') || ''
            options = options.split('|').map(i => {
                const splitVal = i.split(':')
                return {
                    label: splitVal?.[1]?.trim() || '',
                    value: splitVal?.[0]?.trim() || ''
                }
            })
            const value = data?.find(i => i.id === id)
            const selectedOption = options.find(i => i.value == value?.value)
            const htmlContent = ${functionName}?.({name: value?.name, label: value?.label, required: value?.required, options, value: value?.value === \`[\${value?.name}_\${value?.id}]\` ? '' : selectedOption?.label})
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            el?.parentElement.replaceChild(newItem, el)
        })
    `
}