import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment"

import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { writeDataChange } from "../../utils/index"

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px !important",
      width: "100%"
    }
  }
}, { name: 'MuiDatePicker' });

function BasicDatePicker(props) {
  const { label, disabled, required, type, value, name, id, fullWidth } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCheck, setIsCheck] = useState(false)
  const classes = useStyles();

  const isCheckError = useMemo(() => {
    if (required) {
        if (!!selectedDate) {
          return false;
        }
        return true;
    }
    return false;
  }, [selectedDate, required])

  useEffect(() => {
    if (value == `[${name}_${id}]`) {
      setSelectedDate(null)
    } else {
      setSelectedDate(value);
    }
  }, [value])

  const handleDateChange = (date) => {
    setSelectedDate(date);
    let obj = {
      id: id,
      name: name,
      label: label,
      value: date,
      type: 'BasicDatePicker',
      required
    }
    writeDataChange(obj)
    if (!date) {
      setIsCheck(true)
    }
    let data = localStorage.getItem("gjs-inputGrapes");
    window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
  }

  useEffect(() => {
    let obj = {
      id: id,
      name: name,
      label: label,
      value: value,
      type: 'BasicDatePicker',
      required
    }
    writeDataChange(obj)
  }, [name, label])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {
        type === 'date' && (
          <KeyboardDatePicker
            inputVariant="outlined"
            format="Do MMM YYYY"
            margin="normal"
            id="date-picker-inline"
            label={label}
            name={name}
            value={selectedDate}
            disabled={disabled}
            required={required}
            error={isCheckError && isCheck}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={classes.root}
            style={{ width: `${fullWidth}` }}
          />
        )
      }
      {
        type === 'time' && (
          <KeyboardTimePicker
            autoOk
            inputVariant="outlined"
            margin="normal"
            id="date-picker-dialog"
            label={label}
            value={selectedDate}
            disabled={disabled}
            required={required}
            error={isCheckError && isCheck}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change time',
            }}
            className={classes.root}
            style={{ width: `${fullWidth}` }}
          />
        )
      }

      {
        type === 'datetime' && (
          <KeyboardDateTimePicker
            autoOk
            inputVariant="outlined"
            margin="normal"
            id="date-picker-dialog"
            format="yyyy/MM/DD hh:mm A"
            label={label}
            value={selectedDate}
            disabled={disabled}
            required={required}
            error={isCheckError && isCheck}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date time',
            }}
            className={classes.root}
            style={{ width: `${fullWidth}` }}
          />
        )
      }
       
    </MuiPickersUtilsProvider>
  );
}

export default BasicDatePicker;