import React from 'react';
import LearningPlanView from '../BuilderPage/LearningPlanView';
import '../../style.css';

const AppContainer = (props) => {
    return (
        <div>
            <LearningPlanView {...props}/>
        </div>
    )
}

export default AppContainer
