import BasicDatePicker from "./BasicDatePicker"
import { stringGenerateRandom } from "../../utils"

const index = ({ editor, model, view }) => {
  const idString = stringGenerateRandom(15);

  editor.BlockManager.add("BasicDatePicker", {
    label: "<div class='gjs-fonts gjs-f-b1'>BasicDatePicker</div>",
    category: 'Form MUI',
    content: `<BasicDatePicker label='Date picker dialog' type='date' name="outlined" value="[outlined_${idString}]" id=${idString} />`
  });

  editor.DomComponents.addType("BasicDatePicker", {
    model: {
      ...model,
      defaults: {
        component: BasicDatePicker,
        stylable: true,
        editable: true,
        void: true,
        droppable: false,
        attributes: {
          disabled: false
        },
        traits: [
          {
            type: "text",
            label: "Id",
            name: "id"
          },
          {
            type: "text",
            label: "Name",
            name: "name"
          },
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          {
            type: "text",
            label: "Label",
            name: "label"
          },
          {
            type: "checkbox",
            label: "Disabled",
            name: "disabled",
          },
          {
            type: "checkbox",
            label: "Required",
            name: "required",
          },
          {
            type: "select",
            label: "FullWidth",
            name: "fullWidth",
            options: [ // Array of options
                { id: '100%', name: 'FullWidth'},
                { id: 'auto', name: 'Auto'},
              ]
          },
          {
            type: "select",
            options: [ // Array of options
                { id: 'date', name: 'Date'},
                { id: 'time', name: 'Time' },
                { id: 'datetime', name: 'Date & Time'},
            ],
            label: "Type",
            name: "type"
          }
        ]
      }
    },
    view,
    isComponent: el => el.tagName === "BASICDATEPICKER"
  });
}
export default index;