export const getFnToRadioHtml = (functionName = 'toRadioHtml') => {
    return `const ${functionName} = ({ name, label,  options = [], value = "" }) => {
        return \`
            <div class="MuiRadio-customRadio">
                \${
                    options.map(i => \`
                        <label for="\${name}">
                            <span type="radio" class="MuiRadio-InputRadio \${i.value == value ? "checked" : ""}"></span>
                            <span class="MuiRadio-TextRadio">\${i.label}</span>
                        </label>
                    \`).join('\\n')
                }
            </div>
        \`;
    }`
}

export const toRadioHtml = (functionName = 'toRadioHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('RadioMui')
        ${variablesName}?.forEach(el => {
            const id = el.getAttribute('id')
            let options = el.getAttribute('options') || ''
            options = options.split('|').map(i => {
                const splitVal = i.split(':')
                return {
                    label: splitVal?.[1]?.trim() || '',
                    value: splitVal?.[0]?.trim() || ''
                }
            })
            const value = data?.find(i => i.id === id)
            const htmlContent = ${functionName}?.({name: value?.name, label: value?.label, options, value: value?.value === \`[\${value?.name}_\${value?.id}]\` ? '' : value?.value})
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            el?.parentElement.replaceChild(newItem, el)
        })
    `
}