export const CheckboxStyle = `
    .MuiFormGroup-row {
        flex-direction: row!important;
    }
    .MuiFormGroup-root {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .MuiCheckBox-checkboxBlue {
        position: relative;
        margin-top: 1rem;
        margin-right: 1rem;
        padding-left: 1.75rem;
        margin-bottom: 1rem;
    }
    .MuiCheckBox-checkboxBlueReverse {
        margin-left: 1rem;
        padding-right: 1.75rem;
        margin-right: 0;
        padding-left: 0;
    }
    .MuiCheckBox-InputCheckBox {
        opacity: 0;
        position: absolute;
    }
    .MuiCheckBox-IndicatorReverse {
        left: unset!important;
        right: 0;
    }
    .MuiCheckBox-Indicator {
        top: 0px;
        left: 0;
        width: 18px;
        border: 2px solid #d4d7dc;
        height: 18px;
        position: absolute;
        border-radius: 3px;
        pointer-events: none;
        background-color: #FFFFFF;
    }
    .MuiCheckBox-InputCheckBox:checked ~ span {
        color: #FFFFFF;
        border: 2px solid #3785BC;
        background: #3785BC url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+DQo8c3ZnIHdpZHRoPSIxMiIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTIgOSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZD0iTTQuNTc1IDguOTc3cy0uNDA0LS4wMDctLjUzNi0uMTY1TC4wNTcgNS42NGwuODI5LTEuMjI3TDQuNDcgNy4yNjggMTAuOTIxLjA4NmwuOTIzIDEuMTAzLTYuODYzIDcuNjRjLS4xMzQtLjAwMy0uNDA2LjE0OC0uNDA2LjE0OHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPg0KPC9zdmc+) 50% 40% no-repeat;
    }
    .MuiCheckBox-TextBold {
        font-weight: bold;
    }
    .MuiCheckBox-alignRight{
        justify-content: end;
    }
    .MuiCheckBox-alignCenter{
        justify-content: center;
    }
`