const index = (editor) => {
    editor.BlockManager.add("Header Default 1", {
        label: `<div class='gjs-fonts gjs-f-b1'> Header Default 1</div>`,
        category: 'Header Default',
        content: `<div class="header-container">
                        <div class="header-wrapper">
                                <div class="header-logo">
                                    <img
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP8iLUqrt7dqoOHopcIE8C3rnTE98Ns9sZSA&usqp=CAU"
                                        alt="logo"
                                    />
                                </div>
                        <h1 class="header-title">INDIVIDUAL LEARNING PLAN</h1>
                        <h3 class="header-name">(name student)</h3>
                            </div>
                            <div class="header-description">
                                <p>
                                    The Protection of Personal Information Act (POPI) Act of 2013 requires us to inform you how we use, disclose and destroy
                                    personal information we obtain from you. The School is committed to protecting your privacy and will ensure that your personal
                                    information is used appropriately according to the law and its regulations. As a School, we are often required to collect,
                                    share and process students personal information (SPI) in order to support identified learning needs. This information, including
                                    Learning Plans, may be collected and processed by our staff and we make every effort to protect and secure this information.
                                    As a parent/ guardian you are entitled to request access to the information on the learning plan.
                                </p>
                            </div>
                    </div>
            <style>
                .header-wrapper {
                    width: 100%;
                    display: flex;
                    align-items: baseline;
                }
                .header-logo {
                    width: 20%;
                    padding: 0 1rem;
                }
                .header-logo img {
                    width: 100%;
                }
                .header-name {
                    padding-left: 1rem;
                    opacity: 0.5;
                }
            </style>`,
    })

    editor.BlockManager.add("Header Default 2", {
        label: `<div class='gjs-fonts gjs-f-b1'> Header Default 2</div>`,
        category: 'Header Default',
        content: ` <div class="header1-wrapper">
                        <div class="header1-logo">
                        <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP8iLUqrt7dqoOHopcIE8C3rnTE98Ns9sZSA&usqp=CAU"
                            alt="logo"
                        /> 
                        </div>
                        <div class="header-desc">
                            <p>
                            The Protection of Personal Information Act (POPI) Act of 2013 requires us to inform you how we use, disclose and destroy
                            personal information we obtain from you. The School is committed to protecting your privacy and will ensure that your personal
                            information is used appropriately according to the law and its regulations. As a School, we are often required to collect,
                            share and process students personal information (SPI) in order to support identified learning needs.
                            </p>
                        </div>
                    </div>
            <style>
                .header1-wrapper {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-content: center;
                }
                .header1-logo {
                    width: 30%;
                }
                .header1-logo img {
                    width: 90%;
                }
                .header-desc p {
                    width: 100%;
                    padding-left: 10px;
                    margin-bottom: 0px;
                }
            </style>`,
    })

    editor.BlockManager.add("Header Default 3", {
        label: `<div class='gjs-fonts gjs-f-b1'> Header Default 3</div>`,
        category: 'Header Default',
        content: `<div class="header3-container">
                        <div class="header3-title">
                        <h2>
                            Individual
                        </h2>
                        <h2>
                            Education Plan
                        </h2>
                        </div>
                        <div class="header3-logo">
                        <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP8iLUqrt7dqoOHopcIE8C3rnTE98Ns9sZSA&usqp=CAU"
                            alt="logo"
                        />
                        </div>
                    </div>
            <style>
                .header3-container {
                    padding: 0px 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                .header3-logo img {
                    width: 90%;
                }
            </style>`,
    })

    editor.BlockManager.add("Header Default 4", {
        label: `<div class='gjs-fonts gjs-f-b1'> Header Default 4</div>`,
        category: 'Header Default',
        content: `<div class="header4-container">
                    <div class="header4-top">
                    <h3>CONFIDENTIAL</h3>
                    </div>
                    <div class="header4-wrapper">
                    <div class="header4-logo">
                        <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP8iLUqrt7dqoOHopcIE8C3rnTE98Ns9sZSA&usqp=CAU"
                        alt="logo"
                        />
                    </div>
                    <p class="header4-sub-logo">
                        Intensive Studies
                    </p>
                    <h3 class="header4-title">
                        Individualized Education Program
                    </h3>
                    </div>
                </div>
            <style>
                .header4-top {
                    display: flex;
                    justify-content: flex-end;
                }
                .header4-top h3 {
                    font-weight: 400;
                    padding-right: 10px;
                }
                .header4-wrapper {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                }
                .header4-sub-logo {
                    margin: 0;
                }
                .header4-logo {
                    width: 12%;
                }
                .header4-logo img {
                    width: 100%;
                }
                .header4-title {
                    margin: 0.5rem;
                }
            </style>`,
    })

    editor.BlockManager.add("Header Default 5", {
        label: `<div class='gjs-fonts gjs-f-b1'> Header Default 5</div>`,
        category: 'Header Default',
        content: `<div class="header5-container">
                <div class="header5-logo">
                <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP8iLUqrt7dqoOHopcIE8C3rnTE98Ns9sZSA&usqp=CAU"
                    alt="logo"
                />
                </div>
                <div class="header5-wrapper">
                <div class="header5-title">
                    <h3>The International School of Azerbaijan (TISA)</h3>
                    <h3>International Individual Learning Plan (IILP)</h3>
                    <h3>CONFIDENTIAL</h3>
                </div>
                </div>
            </div>
            <style>
            .header5-container {
                padding: 3rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
              .header5-logo {
                width: 20%;
              }
              .header5-logo img {
                width: 100%;
              }
              .header5-title h3:first-child {
                font-weight: 500;
                margin: 0.5rem 0;
              }
              .header5-title h3:nth-child(2) {
                margin: 0.5rem 0;
              }
              .header5-title h3:last-child {
                text-decoration: underline;
                font-weight: 400;
                text-align: right;
              }
            </style>`,
    })

    editor.BlockManager.add("Header Default 6", {
        label: `<div class='gjs-fonts gjs-f-b1'> Header Default 6</div>`,
        category: 'Header Default',
        content: `<div class="header6-container">
                        <div class="header6-logo">
                        <img
                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP8iLUqrt7dqoOHopcIE8C3rnTE98Ns9sZSA&usqp=CAU"
                            alt="logo"
                        />
                        </div>
                        <div class="header6-title">
                        <h3>VICTORIA SHANGHAI ACADEMY (SECONDARY SECTION)</h3>
                        <h3>INDIVIDUALIZED EDUCATION PROGRAM (IEP)</h3>
                        </div>
                    </div>
            <style>
            .header6-container {
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              .header6-title {
                text-align: center;
              }
              .header6-title h3 {
                text-align: center;
                margin: 0.5rem 0;
              }
              .header6-logo {
                width: 15%;
              }
              .header6-logo img {
                width: 100%;
              }
              
            </style>`,
    })

    editor.BlockManager.add("Header Default 7", {
        label: `<div class='gjs-fonts gjs-f-b1'> Header Default 7</div>`,
        category: 'Header Default',
        content: `<div class="header-education-plan">
        <div class="header-image">
          <img
            src="https://www.esctuniversity.com/wp-content/uploads/2021/07/Undegraduate-1.jpg"
            alt=""
          />
        </div>
        <div class="header-body">
          <h3><span>Individual Education Plan </span> for Alfred Stroem</h3>
          <p>Students of determination: <span>Student of Determination</span> </p>
          <p>Date of birth: 31/8/2014 Gender: Male Class: G1DO Grade: Grade 1</p>
          <p>
            Teacher: Ms Donna Edwina Sullivan Start date: 30/9/2021 Review date:
            1/2/2022 Plan number: 7
          </p>
          <p>
            Medical needs: N Gifted & talented: N EAL: N Attendance: NATIONALITY:
            Denmark
          </p>
          <p>
            PRIMARYLANGUAGE: Danish SECONDARYLANGUAGE: English FSM: No In care: No
            ELL: N
          </p>
        </div>
        <div class="header-logo">
          <img
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTP8iLUqrt7dqoOHopcIE8C3rnTE98Ns9sZSA&usqp=CAU"
            alt=""
          />
        </div>
      </div>
            <style>
                .header-education-plan {
                    display: flex;
                }
                
                .header-education-plan .header-image {
                    width: 20%;
                }
                .header-education-plan .header-image img,
                .header-education-plan .header-logo img {
                    width: 100%;
                }
                .header-education-plan .header-body {
                    width: 60%;
                    padding: 0px 10px 0px 20px;
                }
                
                .header-education-plan .header-logo {
                    width: 20%;
                }
                
                .header-education-plan .header-logo img {
                    padding-top: 30px;
                }
            </style>`,
    })
  }
  export default index;