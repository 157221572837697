export const RadioStyle = `
    .MuiRadio-customRadio {
        gap: 24px;
        display: flex;
    }
    .MuiRadio-InputRadio {
        left: 0;
        width: 18px;
        height: 18px;
        position: absolute;
    }
    .MuiRadio-customRadio label {
        cursor: pointer;
        display: flex;
        position: relative;
    }
    .MuiRadio-TextRadio {
        display: flex;
        margin-top: 2px;
        padding-left: 32px;
    }
    .MuiRadio-customRadio label{
        align-items: center;
    }
    span.MuiRadio-InputRadio{
        border: 1px solid #a4a4a4;
        border-radius: 50%;
    }
    span.MuiRadio-InputRadio.checked{
        border-color: #336dda;
    }
    span.MuiRadio-InputRadio.checked::after{
        content: '';
        display: inline-block;
        position: absolute;
        width: 75%;
        height: 75%;
        background-color: #336dda;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`