export const FileUploadStyle = `
.button-upload {
    background: #006e60;
    color: #fff;
    border-radius: 12px;
    padding: 5px 10px;
    cursor: pointer;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;
  }

  .list-version .item-version {
    display: flex;
    cursor: pointer;
    margin-right: 25px;
  }
  .list-version li {
    display: flex;
  }
  .list-version {
    width: 30% !important;
  }  
  .list-version .item-version .item-version-text {
      margin-left: 15px;
  }
  #uploadFile {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`