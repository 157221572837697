import React from "react";
import useLearningPlanIEP from "../../hooks/useLearningPlanIEP";

const LearningPlanView = () => {
  const { getHTMLTemplate } = useLearningPlanIEP();

  return (
    <div id="result-template">
      <div id="gjs">
      </div>
    </div>
      
  );
};

export default LearningPlanView;
