import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
    Typography
} from "@material-ui/core"
import MomentUtils from "@date-io/moment"
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import { makeStyles } from "@material-ui/core/styles";
import { stringGenerateRandom } from "../../utils/index"
import { writeDataChange } from "../../utils/index"
import { DATE_MIN_VALUE } from "../../utils/type";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px !important",
        width: "100%",
        marginRight: "10px",
        height: "55px"
      },
      "& label" : {
        paddingInline: "5px"
      },
      "&" : {
        marginTop: "0px !important",
        marginBottom: "0px !important",
        marginRight: "20px !important"
      },
       "& fieldset legend[class^='PrivateNotchedOutline-legendLabelled-']" : {
            width: "auto",
            height: "11px",
            display: "block",
            padding: "0",
            fontSize: "0.75em",
            maxWidth: "0.01px",
            textAlign: "left",
            transition: "max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            visibility: "hidden"
        },
        "& .MuiInputLabel-outlined": {
            background: "#fff"
        },
      "& fieldset" : {
        top: "-5px",
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        padding: "0 8px",
        overflow: "hidden",
        position: "absolute",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRadius: "inherit",
        pointerEvents: "none"
      },
      "& fieldset legend[class^='PrivateNotchedOutline-legend-']" : {
        padding: "0",
        textAlign: "left",
        transition: "width 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        lineHeight: "11px"
      },
      "& .MuiOutlinedInput-notchedOutline legend": {
          display: "none"
      }
    },
    input: {
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px !important",
          width: "100%",
          marginRight: "10px",
          height: "55px",
          width: "350px"
        },
        "& .MuiOutlinedInput-root textarea": {
            height: "auto !important"
        },
        "&" : {
            marginTop: "0px",
            marginBottom: "0px",
            marginRight: "20px"
        },
        "& fieldset" : {
            top: "-5px",
            left: 0,
            right: 0,
            bottom: 0,
            margin: 0,
            padding: "0 8px",
            overflow: "hidden",
            position: "absolute",
            borderStyle: "solid",
            borderWidth: "1px",
            borderRadius: "inherit",
            pointerEvents: "none"
        },
        "& fieldset legend[class^='PrivateNotchedOutline-legendLabelled-']" : {
            width: "auto",
            height: "11px",
            display: "block",
            padding: "0",
            fontSize: "0.75em",
            maxWidth: "0.01px",
            textAlign: "left",
            transition: "max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            visibility: "hidden"
        },
        "& .MuiInputLabel-outlined" : {
            background: "#fff",
            padding: "0px 5px"
        },
        "& .MuiOutlinedInput-notchedOutline legend": {
            display: "none"
        }
    },
    grid : {
        "&" : {
            position: "relative",
            display: "block"
        }
    },
    girdNext : {
        "&" : {
            position: "relative",
            display: "block",
            marginTop: "15px", 
            paddingLeft: "40px"
        }
    },
    boxGoal: {
        "&" : {
            marginTop: "15px"
        }
    },
    image: {
        "&": {
            paddingTop: "18px",
            cursor: "pointer"
        }
    },
    objectives: {
        display: "block",
        marginBottom: "15px"
    },
    btnObjectives : {
        background: "#006e60",
        color: "#fff",
        borderRadius: "12px",
        padding: "5px 10px",
        cursor: "pointer",
        position: "relative",
        width: "135px",
        textAlign: "center",
        marginLeft: "40px"
    },
    btnGoal : {
        background: "#006e60",
        color: "#fff",
        borderRadius: "12px",
        padding: "5px 10px",
        cursor: "pointer",
        position: "relative",
        width: "100px",
        marginTop: "15px",
        textAlign: "center"
    },
    goalTitle: {
        paddingBottom: "12px",
        fontFamily: "Poppins, Arial, sans-serif",
        fontWeight: 600,
        fontSize: "16px",
        color: "#000000"
    }
  }, { name: 'MuiGoal' });

const GoalObjectives = (props) => {
    const initData = {
        id: stringGenerateRandom(15),
        startTime : DATE_MIN_VALUE,
        endTime: DATE_MIN_VALUE,
        detailGoal: "",
        objectives : [
            {
                id: stringGenerateRandom(10),
                startTime: DATE_MIN_VALUE,
                endTime: DATE_MIN_VALUE,
                detailObjectives: ""
            }
        ]
        
    }
    const classes = useStyles();
    const { id, name, value } = props;
    const [dataGoal, setDataGoal] = useState([initData]);

    const handleChange = (goalId, objectivesId, value, type) => {
        let index = dataGoal.findIndex((item) => item.id == goalId);
        if (index != -1) {
            if (typeof objectivesId != "undefined") {
                let indexObj = dataGoal[index].objectives.findIndex((record) => record.id == objectivesId);
                if (type == 'startTime') {
                    dataGoal[index].objectives[indexObj].startTime = value;
                }

                if (type == 'endTime') {
                    dataGoal[index].objectives[indexObj].endTime = value;
                }

                if (type == 'textfield') {
                    dataGoal[index].objectives[indexObj].detailObjectives = value;
                }
            } else {
                if (type == 'startTime') {
                    dataGoal[index].startTime = value;
                }
                if (type == 'endTime') {
                    dataGoal[index].endTime = value;
                }

                if (type == 'textfield') {
                    dataGoal[index].detailGoal = value;
                }
            }
            setDataGoal(JSON.parse(JSON.stringify(dataGoal)));
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataGoal,
                type: 'GoalObjectiveMUI'
            }
            writeDataChange(obj)
            let data = localStorage.getItem("gjs-inputGrapes");
            window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
        }
    }

    const addRowGoal = (isRowGoal, idGoal) => {
        if (!!isRowGoal) {
            let initNew = {
                id: stringGenerateRandom(15),
                    startTime : DATE_MIN_VALUE,
                    endTime: DATE_MIN_VALUE,
                    detailGoal: "",
                    objectives : [
                        {
                            id: stringGenerateRandom(10),
                            startTime: DATE_MIN_VALUE,
                            endTime: DATE_MIN_VALUE,
                            detailObjectives: ""
                        }
                    ]
            }
            setDataGoal(JSON.parse(JSON.stringify([...dataGoal, initNew])));
            let obj = {
                id: id,
                name: name,
                label: name,
                value: [...dataGoal, initNew],
                type: 'GoalObjectiveMUI'
            }
            writeDataChange(obj)
        } else {
            let objectiveNew = {
                id: stringGenerateRandom(10),
                startTime: DATE_MIN_VALUE,
                endTime: DATE_MIN_VALUE,
                detailObjectives: ""
            }
            let index = dataGoal.findIndex((item) => item.id == idGoal);
            if (index != -1) {
                dataGoal[index].objectives = [...dataGoal[index].objectives, objectiveNew];
            }
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataGoal,
                type: 'GoalObjectiveMUI'
            }
            writeDataChange(obj)
            setDataGoal(JSON.parse(JSON.stringify(dataGoal)));
        }

        let data = localStorage.getItem("gjs-inputGrapes");
        window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
    }

    const removeGoalItem = (goalId, objectiveId) => {
        let index = dataGoal.findIndex((item) => item.id == goalId);
        let dataGoalNew = dataGoal;
        if (index != -1) {
            if (!!objectiveId) {
                dataGoal[index].objectives =  dataGoal[index].objectives.filter((record) => record.id != objectiveId);
                setDataGoal(JSON.parse(JSON.stringify(dataGoal)));
                dataGoalNew = dataGoal;
            } else {
                let dataNew =  dataGoal.filter((record) => record.id != dataGoal[index].id);
                dataGoalNew = dataNew;
                setDataGoal(JSON.parse(JSON.stringify(dataNew)));
            }
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataGoalNew,
                type: 'GoalObjectiveMUI'
            }
            writeDataChange(obj)
        }

        let data = localStorage.getItem("gjs-inputGrapes");
        window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
    }

    const val = (date) => {
        return date === DATE_MIN_VALUE ? null : date
    }

    useEffect(() => {
        if (value == `[${name}_${id}]`) {
            setDataGoal([initData]);
        } else {
            setDataGoal(value);
        }
      }, [value])

    useEffect(() => {
        let obj = {
            id: id,
            name: name,
            label: "Goals",
            value: value,
            required: true,
            type: 'GoalObjectiveMUI'
        }
        writeDataChange(obj)
    }, [name])
    return (
        <MuiPickersUtilsProvider
            utils={MomentUtils}
        >
            {
                !!dataGoal && dataGoal?.length > 0 && dataGoal?.map((record, index) => (
                    <div className={classes.boxGoal} key={record.id}>
                        <Grid className={classes.grid}>
                            <Typography className={classes.goalTitle}>
                                Goal {index + 1}:
                            </Typography>
                            <div className={classes.objectives}>
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    format="Do MMM YYYY"
                                    margin="normal"
                                    name={"startTime"}
                                    label={"Start Date"}
                                    value={val(record.startTime)}
                                    onChange={(value) => handleChange(record.id, undefined, value, 'startTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className={classes.root}
                                />
                                <KeyboardDatePicker
                                    inputVariant="outlined"
                                    format="Do MMM YYYY"
                                    margin="normal"
                                    name={"endTime"}
                                    label={"End Date"}
                                    value={val(record.endTime)}
                                    onChange={(value) => handleChange(record.id, undefined, value, 'endTime')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    className={classes.root}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Write something"
                                    onChange={(event) => handleChange(record.id, undefined, event.target.value, 'textfield')}
                                    value={record.detailGoal}
                                    className={classes.input}
                                />
                                <img
                                    src="/images/delete.png"
                                    className={classes.image}
                                    onClick = {() => removeGoalItem(record.id)}
                                />
                            </div>
                        </Grid>
                        {
                            !!record.objectives && record.objectives?.length > 0 && record.objectives.map((obj) => (
                                <Grid className={classes.girdNext} key={obj.id}>
                                    <div className={classes.objectives}>
                                        <KeyboardDatePicker
                                            inputVariant="outlined"
                                            format="Do MMM YYYY"
                                            margin="normal"
                                            id="date-picker-inline"
                                            name={"objective-start"}
                                            label="Start Date"
                                            value={val(obj.startTime)}
                                            onChange={(value) => handleChange(record.id, obj.id, value, 'startTime')}
                                            KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            }}
                                            className={classes.root}
                                        />
                                        <KeyboardDatePicker
                                            inputVariant="outlined"
                                            format="Do MMM YYYY"
                                            margin="normal"
                                            id="date-picker-inline"
                                            name={"objective-end"}
                                            label="End Date"
                                            value={val(obj.endTime)}
                                            onChange={(value) => handleChange(record.id, obj.id, value, 'endTime')}
                                            KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                            }}
                                            className={classes.root}
                                        />
                                        <TextField
                                            multiline
                                            variant="outlined"
                                            label="Write something"
                                            onChange={(event) => handleChange(record.id, obj.id, event.target.value, 'textfield')}
                                            value={obj.detailObjectives}
                                            className={classes.input}
                                        />
                                        <img
                                            src="/images/delete.png"
                                            className={classes.image}
                                            onClick = {() => removeGoalItem(record.id, obj.id)}
                                        />
                                    </div>
                                </Grid>
                            ))
                        }
                        
                        <Typography
                                className={classes.btnObjectives}
                                onClick={() => addRowGoal(0, record.id)}
                            >
                                Add Objectives
                            </Typography>
                    </div>
                ))
            }
            
            <Typography
                    className={classes.btnGoal}
                    onClick={() => addRowGoal(1)}
                >
                        Add Goals
                </Typography>
        </MuiPickersUtilsProvider>
    )
}

export default GoalObjectives;