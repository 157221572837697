import React from "react";
import "react-datepicker/dist/react-datepicker.min.css";
import useGrapesjsMain from "../hooks/useGrapesjsMain"

const GrapesjsMain = () => {
  const { onSaveTemplate, getHTMLTemplate, getTemplateByType } = useGrapesjsMain();

  return (
    <div>
      <div id="gjs">
      </div>
    </div>
      
  );
};

export default GrapesjsMain;
