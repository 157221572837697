import CheckboxMui from "./CheckboxMui";
import { stringGenerateRandom } from "../../utils"

const index = ({ editor, model, view }) => {
    const idString = stringGenerateRandom(15);
    
    editor.BlockManager.add("CheckboxMui", {
      label: "<div class='gjs-fonts gjs-f-b1'>CheckboxMui</div>",
      category: 'Form MUI',
      content: `<CheckboxMui options="value:name" name="outlined" label="This is header checkbox" value="[outlined_${idString}]" direction="row" id="${idString}" />`
    });
  
    editor.DomComponents.addType("CheckboxMui", {
      model: {
        ...model,
        defaults: {
          component: CheckboxMui,
          stylable: true,
          editable: true,
          void: false,
          droppable: false,
          traits: [
            {
              type: "text",
              label: "Id",
              name: "id"
            },
            {
              type: "text",
              label: "Value",
              name: "value",
            },
            {
                type: "text",
                label: "Name",
                name: "name"
            },
            {
              type: "text",
              label: "Label",
              name: "label"
            },
            {
              type: "checkbox",
              label: "Required",
              name: "required",
            },
            {
                type: "text",
                label: "Options",
                name: "options"
            },
            {
              type: "select",
              label: "Direction",
              name: "direction",
              options: [ // Array of options
                { id: 'row', name: 'row'},
                { id: 'column', name: 'column'},
              ]
            },
            {
              type: "checkbox",
              label: "Reverse",
              name: "reverse",
            },
            {
              type: "checkbox",
              label: "Bold",
              name: "bold",
            },
            {
              type: "select",
              label: "Align",
              name: "align",
              options: [ // Array of options
                { id: 'Left', name: 'left'},
                { id: 'Center', name: 'center'},
                { id: 'Right', name: 'right'}
              ]
            }
          ]
        }
      },
      view,
      isComponent: el => el.tagName === "CHECKBOXMUI"
    });
}
export default index;