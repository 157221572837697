export const getFnToBasicDatePickerHtml = (functionName = 'toBasicDatePickerHtml') => {
    return `const ${functionName} = ({ label, value, required, fullscreen = true }) => {
        const isValidValue = !!moment?.(value).isValid()
        return \`
            <div class="MuiFormControl-root MuiTextField-root MuiDatePicker-root MuiFormControl-marginNormal" style="\${fullscreen ? 'width: 100%;' : ''}">
                    <label class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined \${isValidValue ? 'MuiInputLabel-shrink MuiFormLabel-filled' : ''}"
                        data-shrink="true" for="date-picker-inline"
                        id="date-picker-inline-label">
                            \${label}
                            \${required
                                ? '<span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>'
                                : ""}
                    </label>
                <div
                    class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-adornedEnd MuiOutlinedInput-adornedEnd">
                    <div class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputAdornedEnd MuiOutlinedInput-inputAdornedEnd">
                        \${isValidValue ? moment?.(value)?.format('Do MMMM YYYY') : label}
                    </div>
                    <div class="MuiInputAdornment-root MuiInputAdornment-positionEnd">
                        <div class="MuiButtonBase-root MuiIconButton-root" tabindex="0"
                            type="button" aria-label="change date" style="display: flex; align-items: center; padding: 12px;">
                            <svg style="width: 24px; height: 24px;" class="MuiSvgIcon-root"
                                focusable="false" viewBox="0 0 24 24"
                                aria-hidden="true">
                                <path
                                    d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z">
                                </path>
                                <path fill="none" d="M0 0h24v24H0z"></path>
                            </svg>
                        </div>
                    </div>
                    <fieldset aria-hidden="true"
                        class="PrivateNotchedOutline-root-1 MuiOutlinedInput-notchedOutline">
                        <legend
                            class="PrivateNotchedOutline-legendLabelled-3 \${isValidValue ? 'PrivateNotchedOutline-legendNotched-4' : ''}">
                            <span>\${label}\${required ? "&nbsp;*" : ""}</span></legend>
                    </fieldset>
                </div>
            </div>
            \`
    }`
}

export const toBasicDatePickerHtml = (functionName = 'toBasicDatePickerHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('BasicDatePicker')
        ${variablesName}.forEach(datePicker => {
            const value = datePicker.getAttribute('value')
            const label = datePicker.getAttribute('label')
            const required = datePicker.getAttribute('required')
            const id = datePicker.getAttribute('id')
            const name = datePicker.getAttribute('name')
            const htmlContent = ${functionName}?.({ label, value: value === \`[\${name}_\${id}]\` ? '' : value, required })
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            datePicker?.parentElement.replaceChild(newItem, datePicker)
        })
    `
}