import React, { useState, useMemo, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from '@material-ui/core/FormLabel';
import { writeDataChange } from "../../utils/index"


const useStyles = makeStyles({
  customRadio: {
    "&": {
      display: "flex",
      gap: "24px"
    },
    "& label": {
      position: "relative",
      display: "flex",
      cursor: "pointer"
    }
  }, 
  InputRadio: {
    "&": {
      position: "absolute",
      left: "0",
      width: "18px",
      height: "18px"
    },
    "&::before": {
      content: "",
      position: "absolute",
      height: "18px",
      width: "18px",
      border: "2px solid #6100ed",
      borderRadius: "18px",
      left: "0",
      cursor: "pointer"
    },
    "&:checked::before": {
      background: "#fff"
    },
    "&:checked::after": {
      content: "",
      position: "absolute",
      left: "6px",
      top: "6px",
      background: "#6100ed",
      height: "10px",
      width: "10px",
      borderRadius: "10px"
    },
  },
  TextRadio: {
    display: "flex",
    paddingLeft: "32px",
    marginTop: "2px"
  }
}, { name: 'MuiRadio' });

const RadioMui = (props) => {
  const { label, options, name, id, required, value } = props;
  const [radioValue, setRadioValue] = useState('');
  const classes = useStyles();
  
  const handleChange = (event) => {
    setRadioValue(event.target.value);
    let obj = {
      id: id,
      name: name,
      label: label,
      required: required,
      value: event.target.value,
      type: 'RadioMui'
    }
    writeDataChange(obj)
    let data = localStorage.getItem("gjs-inputGrapes");
    window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
  };

  const listOptions = useMemo(() => {
    let arrNew = {};
    options?.split("|").forEach((item) => {
        if (item.trim() !== "" && item.includes(":")) {
            let indexArr = item.trim()?.split(":");
            arrNew[indexArr[0]] = indexArr[1];
        }
    });
    return arrNew;
  }, [options])

  useEffect(() => {
    if (value == `[${name}_${id}]`) {
      setRadioValue("")
    } else {
      setRadioValue(value);
    }
  }, [value])

  useEffect(() => {
    let obj = {
      id: id,
      name: name,
      label: label,
      required: required,
      value: value,
      type: 'RadioMui'
    }
    writeDataChange(obj)
  }, [name, label, required])

  return (
      <div className={classes.customRadio}>
      {
          !!listOptions && Object.keys(listOptions).length > 0 && Object.keys(listOptions)?.map((key) => 
            (
            <label for={label}>
                <input type="radio" className={classes.InputRadio} name={label} value={key} onChange={handleChange} checked={radioValue == key}/>
                <span className={classes.TextRadio}>{ listOptions[key] }</span>
            </label>
          ))
      }
      </div>
  );
}

export default RadioMui;