import React from 'react';
import GrapesjsView from '../BuilderPage/GrapesjsView';
import '../../style.css';

const AppContainer = (props) => {
    return (
        <div>
            <GrapesjsView {...props}/>
        </div>
    )
}

export default AppContainer
