import { getFnToAvatarImageHtml, toAvatarImageHtml } from "./AvatarImage/AvartaImageScript"
import { getFnToCheckboxHtml, toCheckboxHtml } from "./Checkbox/checkboxScript"
import { getFnToBasicDatePickerHtml, toBasicDatePickerHtml } from "./DatePicker/datePickerScript"
import { getFnToFileUploadHtml, toFileUploadHtml } from "./FileUpload/fileUploadScript"
import { getFnToGoalHtml, toGoalHtml } from "./GoalExample/goalScript"
import { getFnToImageMuiHtml, toImageMuiHtml } from "./Image/imageMuiScript"
import { getFnToRadioHtml, toRadioHtml } from "./Radio/radioScript"
import { getFnToSelectMuiHtml, toSelectMuiHtml } from "./Select/selectMuiScript"
import { getFnToTableMultiColumnHtml, toTableMultiColumnHtml } from "./Table/tableMultiColumnScript"
import { getFnToTableUploadFileHtml, toTableUploadFileHtml } from "./Table/tableUploadFileScript"
import { getFnToTextFieldHtml, toTextFieldHtml } from "./TextField/textFieldScript"
import { CUSTOM_STYLE, STYLE_MUI } from "./style"

const getAllScriptString = (data = '') => {
    const fnScripts = [
        getFnToBasicDatePickerHtml('toBasicDatePickerHtml'),
        getFnToTextFieldHtml('toTextFieldHtml'),
        getFnToGoalHtml('toGoalHtml', 'toTextFieldHtml', 'toBasicDatePickerHtml'),
        getFnToCheckboxHtml('toCheckboxHtml'),
        getFnToImageMuiHtml('toImageMuiHtml'),
        getFnToFileUploadHtml('toFileUploadHtml'),
        getFnToRadioHtml('toRadioHtml'),
        getFnToSelectMuiHtml('toSelectMuiHtml'),
        getFnToTableMultiColumnHtml('toTableMultiColumnHtml'),
        getFnToTableUploadFileHtml('toTableUploadFileHtml'),
        getFnToAvatarImageHtml('toAvatarImageHtml')
    ]
    const toHTMLScript = [
        toBasicDatePickerHtml('toBasicDatePickerHtml', 'basicDatePickers'),
        toTextFieldHtml('toTextFieldHtml', 'textFields'),
        toGoalHtml('toGoalHtml', 'goals'),
        toCheckboxHtml('toCheckboxHtml', 'checkboxes'),
        toImageMuiHtml('toImageMuiHtml', 'muiImages'),
        toFileUploadHtml('toFileUploadHtml', 'fileUploads'),
        toRadioHtml('toRadioHtml', 'radios'),
        toSelectMuiHtml('toSelectMuiHtml', 'muiSelects'),
        toTableMultiColumnHtml('toTableMultiColumnHtml', 'tableMultiColumns'),
        toTableUploadFileHtml('toTableUploadFileHtml', 'tableUploadFiles'),
        toAvatarImageHtml('toAvatarImageHtml', 'avatarImages')
    ] 

    const scripts = [`const data = JSON.parse(${JSON.stringify(data)})`, fnScripts.join('\n'), toHTMLScript.join('\n')]
    return scripts.join('\n')
}

export const toTextHtmlContent = (
    originStyle,
    bodyContent,
    data
) => {
    const script = getAllScriptString(data)
    return `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>IEP PDF</title>
                <script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment.min.js"></script>
                <style>
                    ${originStyle}
                    ${STYLE_MUI}
                    ${CUSTOM_STYLE}
                </style>
            </head>
            <body>
                ${bodyContent}
            </body>
            <script>
                ${script}
            </script>
        </html>
    `
}