import AppContainer from "./containers/App"
import GrapesjsView from "./containers/App/view"
import LearningPlanView from "./containers/App/learningPlan"

export const routerIndex = [
    {
        id: 1,
        path: "/",
        component: AppContainer,
    },
    {
        id: 2,
        path: "/edit-template-iep/:templateId",
        component: AppContainer,
    },
    {
        id: 3,
        path: "/result-template/:templateId",
        component: GrapesjsView,
    },
    {
        id: 4,
        path: "/iep-template/:templateId",
        component: LearningPlanView,
    }
]