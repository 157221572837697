import React, { useState, useEffect, useMemo } from "react";
import {
    TextField
} from "@material-ui/core"
import { stringGenerateRandom } from "../../utils/index"
import { writeDataChange } from "../../utils/index"
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px !important",
        minHeight: "55px"
      },
      "& textarea": {
        border: "none !important;"
      },
      "& .MuiOutlinedInput-root textarea": {
        minHeight: "55px !important"
        },
    },
    customers: {
        "&": {
            borderCollapse: "collapse",
            width: "100%"
        },
        "& td, & th": {
            padding: "10px"
        },
        "& th:last-child": {
            borderRight: "none"
        },
        "& tr:nth-child(even)": {
            backgroundColor: "#fff"
        },
        "& th": {
            paddingTop: "8px",
            paddingBottom: "8px",
            textAlign: "left",
            backgroundColor: "#67afef",
            color: "white",
            borderRight: "1px solid #ddd",
            paddingLeft: "10px",
            borderTop: "none",
            borderLeft: "none",
            borderBottom: "none"
        }
    },
    addRow: {
        "&": {
            border: "1px dashed #67afef",
            textAlign: "center",
            marginLeft: "10px",
            display: "flex"
        },
        "& td": {
            width: "100%",
            margin: "0 auto",
            cursor: "pointer"
        }
    }
  }, { name: "MuiTextFieldTable" });

const TableMultiColumns = (props) => {
    const { id, name, value, columns } = props;
    const [dataTable, setDataTable] = useState([]);
    const classes = useStyles();

    const listColumns = useMemo(() => {
        return columns?.split("|");
    }, [columns])

    const handleChange = (rowId, column, value) => {
        let dataNew = dataTable;
        let index = dataNew.findIndex((item) => item.id == rowId);
        if (index != -1) {
            dataNew[index][column] = value;
            setDataTable(JSON.parse(JSON.stringify(dataNew)));
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataNew,
                type: 'TableMultiColumns'
            }
            writeDataChange(obj)
            let data = localStorage.getItem("gjs-inputGrapes");
            window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
        }
    }

    const addRow = () => {
        let initDataNew = {
            id: stringGenerateRandom(10),
        }
        let item = "{";
        if (listColumns.length > 0) {
            listColumns.forEach((record, index) => {
                item += `"${record}" : ""`;
                if (index != listColumns.length - 1) {
                    item += ","
                };
            })
        }
        item += "}";
        if (item != "{}") {
            item = JSON.parse(item);
            initDataNew = {...initDataNew, ...item}
            let dataNew = [...dataTable, initDataNew];
            setDataTable(JSON.parse(JSON.stringify(dataNew)));
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataNew,
                type: 'TableMultiColumns'
            }
            writeDataChange(obj)
            let data = localStorage.getItem("gjs-inputGrapes");
            window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
        }
    }

    const removeItem = (rowId) => {
        let index = dataTable.findIndex((item) => item.id == rowId);
        if (index != -1) {
            let dataNew =  dataTable.filter((record) => record.id != rowId);
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataNew,
                type: 'TableMultiColumns'
            }
            writeDataChange(obj)
            setDataTable(JSON.parse(JSON.stringify(dataNew)));
            let data = localStorage.getItem("gjs-inputGrapes");
            window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
        }
    }

    useEffect(() => {
        const listColumnItem = columns?.split("|");
        if (value == `[${name}_${id}]` && listColumnItem.length > 0) {
            let initData = {
                id: stringGenerateRandom(10),
            }
            let item = "{";
            if (listColumnItem.length > 0) {
                listColumnItem.forEach((record, index) => {
                    item += `"${record}" : ""`;
                    if (index != listColumnItem.length - 1) {
                        item += ","
                    };
                })
            }
            item += "}";
            console.log({item})
            if (item != "{}") {
                let newItem = JSON.parse(item);
                initData = [{...initData, ...newItem}];
                setDataTable(JSON.parse(JSON.stringify(initData)));
            }
        } else {
            setDataTable(value);
        }
      }, [value, columns, name, id])

    return (
        <table className={classes.customers}>
            <thead>
                <tr>
                    {
                        listColumns.length > 0 && listColumns.map((item) => (
                            <th>{item}</th>
                        ))
                    }
                    {
                        dataTable.length > 1 && (
                            <th style={{width: "10px"}}></th>
                        )

                    }
                </tr>
            </thead>
            <tbody>
                    {
                        Array.isArray(dataTable) && dataTable?.length > 0 && dataTable?.map((record) => {
                            return (
                                <tr>
                                    {
                                        !!listColumns && listColumns?.length > 0 && listColumns?.map((item) => (
                                            <td>
                                                <TextField
                                                    id={record?.id}
                                                    label={item}
                                                    name={`${item}_${record?.id}`}
                                                    variant="outlined"
                                                    value={record[item]}
                                                    multiline
                                                    onChange={(e) => handleChange(record.id, item, e.target.value)}
                                                    className={classes.root}
                                                    style={{ width: `100%` }}
                                                />
                                            </td>
                                        ))
                                    }
                                    {
                                        dataTable.length > 1 && (
                                            <td style={{width: "10px"}}>
                                                <img
                                                    style={{ cursor: "pointer" }}
                                                    src="/images/delete.png"
                                                    onClick = {() => removeItem(record.id)}
                                                />
                                            </td>
                                        )
                                    }
                                </tr>
                            )
                        })
                    }
            </tbody>
            
            <tfooter>
                <tr className={classes.addRow}> 
                    <td colspan={listColumns.length} onClick={addRow}>
                        Add Item
                    </td>
                </tr>
            </tfooter>
        </table>
    )
}

export default TableMultiColumns;