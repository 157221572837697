import React, { useState, useEffect, useRef } from "react";
import { writeDataChange, getFileUrl } from "../../utils"

const AvatarImageMUI = (props) => {
  const { name, label, id, required, value } = props;
  const [urlImage, setUrlImage] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (value == `[${name}_${id}]`) {
      setUrlImage("/images/uploadfile.png")
    } else {
      setUrlImage(value);
    }
  }, [value])

  const handleFileChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      const dataFile = event.target.files[0];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (!validImageTypes.includes(dataFile.type)) {
          throw new Error("Not an image")
        }

        let objOld = {
          id: id,
          name: name,
          label: label,
          required: false,
          type: 'AvatarImageMUI'
        }

        localStorage.setItem("currentUpload", JSON.stringify(objOld));
        window.parent.postMessage({ eventName: 'uploadAvatarImage', dataFile: dataFile }, '*');
    } catch (err) {
       console.log(err)
    }
  }

  window.addEventListener('message', function(event) {
    if (event.data.eventName == 'parentSendAvatarImage') {
      const dataImage = event.data.imageData;
      if (!!dataImage) {
        const urlFile = getFileUrl(dataImage.domain, dataImage.keyFile);
        let currentUpload = JSON.parse(localStorage.getItem("currentUpload"));

        if (currentUpload.id == id) {
          let obj = {
            id: id,
            name: name,
            label: label,
            value: urlFile,
            required: false,
            type: 'AvatarImageMUI'
          }
          setUrlImage(urlFile);
          writeDataChange(obj)
          let data = localStorage.getItem("gjs-inputGrapes");
          window.parent.postMessage({ eventName: 'marioChange', data : data}, dataImage.domain);
        }
      }
    }
  })

  const handleClick = () => {
    inputRef.current.click();
  }

  useEffect(() => {
    let obj = {
      id: id,
      name: name,
      label: label,
      required: false,
      value: value,
      type: 'AvatarImageMUI'
    }
    writeDataChange(obj)
  }, [name, label, required])
  return (
    <div className={`wrapper-item ${urlImage != "/images/uploadfile.png" ? "wrapper-box" : ""} `}>
      <input
          type="file"
          ref={inputRef}
          onChange={(event) => {
          handleFileChange(event)
          event.target.value = null;
          }}
          style={{display: "none" }}
          accept="image/*"
      />
      <div className="file-wrapper upload-avatar" onClick={handleClick}>
          {
            urlImage != "/images/uploadfile.png" ? (
              <img className="file-img" src={urlImage} alt="upload-img" />
            ) : (
              <>
                <img className="no-file-img" src="/images/uploadfile.png" alt="upload-img" />
                <h4 className="upload-image">Avatar Image</h4>
              </>
            )
          }
      </div>
  </div>
  );
}

export default AvatarImageMUI;