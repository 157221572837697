export const ImageMuiStyle = `
    .wrapper-item {
        width: 100%;
    }
    .file-title {
        margin-top: 10px;
        margin-right: 0px;
        margin-bottom: 10px;
        margin-left: 0px;
    }
    .file-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-top-width: 1px;
        border-right-width: 1px;
        border-bottom-width: 1px;
        border-left-width: 1px;
        border-top-style: dashed;
        border-right-style: dashed;
        border-bottom-style: dashed;
        border-left-style: dashed;
        border-top-color: rgba(0, 0, 0, 0.54);
        border-right-color: rgba(0, 0, 0, 0.54);
        border-bottom-color: rgba(0, 0, 0, 0.54);
        border-left-color: rgba(0, 0, 0, 0.54);
        border-image-source: initial;
        border-image-slice: initial;
        border-image-width: initial;
        border-image-outset: initial;
        border-image-repeat: initial;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
        cursor: pointer;
        width: 100%;
        height: 175px;
        position: relative;
    }
    .no-file-img {
        width: 62px;
    }
    .upload-image {
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        line-height: 1.5;
        font-size: 16px;
        letter-spacing: 0.00938em;
        font-weight: 400;
    }
    .type-file {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.54);
        margin-top: 0px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        font-weight: 400;
    }
    .required-icon {
        color: red;
    }
`