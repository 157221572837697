import React, { useState, useEffect, useRef } from "react";
import {
    TextField
} from "@material-ui/core"
import { stringGenerateRandom } from "../../utils/index"
import { writeDataChange, getFileUrl } from "../../utils/index"
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles({
    root: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px !important",
        minHeight: "55px"
      },
      "& textarea": {
        border: "none !important;"
      },
      "& .MuiOutlinedInput-root textarea": {
        height: "auto !important"
      },
    },
    customers: {
        "&": {
            borderCollapse: "collapse",
            width: "100%"
        },
        "& td, & th": {
            padding: "10px"
        },
        "& th:last-child": {
            borderRight: "none"
        },
        "& tr:nth-child(even)": {
            backgroundColor: "#fff"
        },
        "& th": {
            paddingTop: "8px",
            paddingBottom: "8px",
            textAlign: "left",
            backgroundColor: "#67afef",
            color: "white",
            borderRight: "1px solid #ddd",
            paddingLeft: "10px",
            borderTop: "none",
            borderLeft: "none",
            borderBottom: "none"
        }
    },
    addRow: {
        "&": {
            textAlign: "center",
            margin: "20px 10px 0px",
            display: "flex"
        },
        "& td": {
            width: "100%",
            margin: "0 auto",
            cursor: "pointer"
        }
    },
    textLink: {
        "&": {
            cursor: "pointer",
            textDecoration: "underline",
            color: "#3c94ff"
        }
    }
  }, { name: "MuiTextFieldFile" });

const TableUploadFile = (props) => {
    const { id, name, value } = props;
    const [dataFileTable, setDataFileTable] = useState([]);
    const inputRef = useRef();
    const classes = useStyles();

    const handleClick = () => {
        inputRef.current.click();
    }

    const handleFileChange = (event) => {
        event.preventDefault();
        event.stopPropagation();
        try {
          const dataFile = event.target.files[0];
          window.parent.postMessage({ eventName: 'uploadTableFile', dataFile: dataFile }, '*');
        } catch (err) {
           console.log(err)
        }
    }

    window.addEventListener('message', function(event) {
        if (event.data.eventName == 'parentSendTableImage') {
          const dataImage = event.data.imageData;
          if (!!dataImage) {
            const urlFile = getFileUrl(dataImage.domain, dataImage.keyFile);
            let currentTime = moment().format("DD/MM/YYYY hh:mm");
            let newItem = {
                id: stringGenerateRandom(10),
                name: dataImage.nameFile,
                urlFile: urlFile,
                time: currentTime,
                type: ""
            }
            let dataNew = [...dataFileTable, newItem];
            setDataFileTable(JSON.parse(JSON.stringify(dataNew)));

            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataNew,
                type: 'TableUploadFile'
            }
            writeDataChange(obj);
            let data = localStorage.getItem("gjs-inputGrapes");
            window.parent.postMessage({ eventName: 'marioChange', data : data}, dataImage.domain);
          }
        }
      })

    const handleChange = (rowId, value) => {
        let dataNew = [...dataFileTable];
        let index = dataNew.findIndex((item) => item.id == rowId);
        if (index != -1) {
            dataNew[index].type = value;
            setDataFileTable(dataNew);
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataNew,
                type: 'TableUploadFile'
            }
            writeDataChange(obj)
            let data = localStorage.getItem("gjs-inputGrapes");
            window.parent.postMessage({ eventName: 'marioChange', data : data}, "*");
        }
    }

    const removeItem = (rowId) => {
        let index = dataFileTable.findIndex((item) => item.id == rowId);
        if (index != -1) {
            let dataNew =  dataFileTable.filter((record) => record.id != rowId);
            setDataFileTable([...dataNew]);
            let obj = {
                id: id,
                name: name,
                label: name,
                value: dataNew,
                type: 'TableUploadFile'
            }
            writeDataChange(obj)
        }

        let data = localStorage.getItem("gjs-inputGrapes");
        window.parent.postMessage({ eventName: 'marioChange', data : data}, '*');
    }

    const downloadFile = (nameFile, urlFile) => {
        window.parent.postMessage({ eventName: 'downloadTableFile', urlFile: urlFile, nameFile: nameFile }, '*');
    }

    useEffect(() => {
        if (value != `[${name}_${id}]`) {
            setDataFileTable(value);
        }
      }, [name, id])

    return (
        <div className="upload-container">
        <table className={classes.customers}>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Time uploaded</th>
                    <th>Type</th>
                    {
                        dataFileTable.length > 0 && (
                            <th style={{width: "10px"}}></th>
                        )
                    }
                </tr>
            </thead>
            <tbody>
                    {
                        Array.isArray(dataFileTable) && dataFileTable?.length > 0 && dataFileTable?.map((record) => (
                                <tr>
                                    <td>
                                        <p onClick={() => downloadFile(record.name, record.urlFile)} className={classes.textLink}>
                                            {record.name}
                                        </p>
                                    </td>
                                    <td>{record.time}</td>
                                    <td>
                                        <TextField
                                            id={record?.id}
                                            label="Type"
                                            name={`type_${record?.id}`}
                                            variant="outlined"
                                            value={record.type}
                                            onChange={(e) => handleChange(record.id, e.target.value)}
                                            className={classes.root}
                                            style={{ width: `100%` }}
                                        />
                                    </td>
                                    <td style={{width: "10px"}}>
                                        <img
                                            style={{ cursor: "pointer" }}
                                            src="/images/delete.png"
                                            onClick = {() => removeItem(record.id)}
                                        />
                                    </td>
                                </tr>
                            ))
                    }
            </tbody>
            
                
        </table>
        <div className={classes.addRow}> 
                <div className="wrapper-item">
                    <input
                        type="file"
                        ref={inputRef}
                        onChange={(event) => {
                        handleFileChange(event)
                        event.target.value = null;
                        }}
                        style={{display: "none" }}
                    />
                    <div className="file-wrapper" onClick={handleClick}>
                        <img className="no-file-img" src="/images/uploadfile.png" alt="upload-img" />
                        <h4 className="upload-image">Upload Image</h4>
                    </div>
                </div>
        </div>
    </div>

    )
}

export default TableUploadFile;