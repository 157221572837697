export const typeEnumTemplate = {
    Default: 0,
    Header: 1,
    Footer: 2,
    Page: 3
}

export const CategoryTemplate = {
    Default: "Others",
    Header: "Header Template",
    Footer: "Footer Template",
    Page: "Page Template"
}

export const DATE_FORMAT = "yyyy-MM-dd"

export const DATE_RESULT = "dd-MM-yyyy"

export const FULL_DATE_RESULT = "dd-MM-yyyy HH:mm"

export const FULL_DATE_FORMAT = "yyyy-MM-dd HH:mm"

export const DATE_MIN_VALUE = "0001-01-01T00:00:00"

export const ACCESS_TOKEN = 'ACCESS_TOKEN';