
import moment from "moment"
import { CategoryTemplate, DATE_MIN_VALUE } from "./type"

export const utcToLocalTime = (time, FORMAT) => {
    if (time === DATE_MIN_VALUE) return ""
    try {
        return moment.utc(time).local().format(FORMAT || "yyyy-MM-DD");
    } catch {
        return ""
    }
}

export const stringGenerateRandom = (len) => {
    var text = "";
    
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIKLMNOPQRSTUVWXYZ0123456789";
    
    for (var i = 0; i < len; i++)
      text += charset.charAt(Math.floor(Math.random() * charset.length));
    
    return text;
}

export const writeDataChange = (obj) => {
  if (obj.hasOwnProperty('id') && obj.hasOwnProperty('name') && obj.hasOwnProperty('value') 
        && obj.hasOwnProperty('type') && obj.hasOwnProperty('label')) {
    const isAdmin = window.location.pathname.includes("edit-template-iep")
    let data = localStorage.getItem("gjs-inputGrapes");
    if(isAdmin)
    {
      let objNew = {
        id: obj.id,
        name: obj.name,
        label: obj.label,
        required: !!obj.required,
        type: obj.type,
        value: obj.value
      };
      if (data != 'undefined' && !!data) {
          let localGrapes = JSON.parse(data);
          // Find by Id
          const objIndex = localGrapes.findIndex(i => i.id == obj.id && i.type == obj.type)
          if(objIndex > -1){
            localGrapes[objIndex].name = obj.name
            localGrapes[objIndex].label = obj.label
            localGrapes[objIndex].required = obj.required
            localGrapes[objIndex].value = obj.value
            localStorage.setItem('gjs-inputGrapes', JSON.stringify(localGrapes));
          }else{
            // Find by name
            const objNameTypes = localGrapes.filter((i) => i.name == obj.name && i.type == obj.type)
            if(!objNameTypes?.length){
              let localGrapeNew = [...localGrapes, objNew];
              localStorage.setItem('gjs-inputGrapes', JSON.stringify(localGrapeNew));
            }else{
              const objNameType = {
                ...objNameTypes[0],
                ...objNew
              }
              let localGrapeNew = localGrapes.filter((i) => i.name != obj.name || i.type != obj.type)
              localGrapeNew = [...localGrapeNew, objNameType]
              localStorage.setItem('gjs-inputGrapes', JSON.stringify(localGrapeNew));
            }
          }
      } else {
        localStorage.setItem('gjs-inputGrapes', JSON.stringify([objNew]));
      }
      return
    }
    if (data != 'undefined' && !!data) {
        let localGrapes = JSON.parse(data);
        let index = localGrapes.findIndex((item) => item.id == obj.id && item.type == obj.type);
        if (index != -1) {
          localGrapes[index].value = obj.value;
          localStorage.setItem('gjs-inputGrapes', JSON.stringify(localGrapes));
        }
    }
  }
}

export const getTypeItemData = (id, type) => {
  const inputGrapes = localStorage.getItem("gjs-inputGrapes")
  let data = inputGrapes ? JSON.parse(localStorage.getItem("gjs-inputGrapes")) : [];
  return data.find((record) => record.id == id && record.type == type)?.value;

} 

export const getFileUrl = (domain, fileName) => {
  if (!fileName || !fileName.trim()) return ""
  return `${domain}/api/file/viewer?key=${fileName}`
}

export const convertCategoryNameByType = (type) => {
  if(!type) return CategoryTemplate.Default;
  return CategoryTemplate[type] || CategoryTemplate.Default
}