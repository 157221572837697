import FileUploadMUI from './FileUploadMui';
import { stringGenerateRandom } from "../../utils"

const index = ({editor, model, view }) => {
  const idString = stringGenerateRandom(15);
  editor.BlockManager.add("FileUploadMUI", {
    label: "<div class='gjs-fonts gjs-f-b1'>FileUploadMUI</div>",
    category: 'Form MUI',
    content: `<FileUploadMUI label="Outlined" variant="outlined" name="outlined" value="[outlined_${idString}]" id=${idString} />
        <style>
        .button-upload {
            background: #006e60;
            color: #fff;
            border-radius: 12px;
            padding: 5px 10px;
            cursor: pointer;
            position: relative;
            display: inline-block;
            margin-bottom: 10px;
          }

          .list-version .item-version {
            display: flex;
            cursor: pointer;
            margin-right: 25px;
          }
          .list-version li {
            display: flex;
          }
          .list-version {
            width: 30% !important;
          }  
          .list-version .item-version .item-version-text {
              margin-left: 15px;
          }
          #uploadFile {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 100%;
            opacity: 0;
            cursor: pointer;
          }
          
        </style>
    `
  });

  editor.DomComponents.addType("FileUploadMUI", {
    model: {
        ...model,
      defaults: {
        component: FileUploadMUI,
        stylable: true,
        editable: true,
        void: true,
        droppable: false,
        attributes: {
          label: "Outlined",
          variant: "outlined"
        },
        traits: [
          {
            type: "text",
            label: "Id",
            name: "id"
          },
          {
            type: "text",
            label: "Name",
            name: "name"
          },
          {
            type: "text",
            label: "Value",
            name: "value",
          },
          {
            type: "text",
            label: "Label",
            name: "label"
          }
        ]
      }
    },
    view,
    isComponent: el => el.tagName === "FILEUPLOADMUI"
  });
}
export default index;