import GoalObjectives from "./GoalObjectives"
import { stringGenerateRandom } from "../../utils"

const index = ({ editor, model, view }) => {
    const idString = stringGenerateRandom(15);
    editor.BlockManager.add("GoalObjectiveMUI", {
      label: "<div class='gjs-fonts gjs-f-b1'>Goal Buider</div>",
      category: 'Form MUI',
      content: `<GoalObjectiveMUI name="outlined" value="[outlined_${idString}]" id="${idString}"/>`
    });
  
    editor.DomComponents.addType("GoalObjectiveMUI", {
      model: {
        ...model,
        defaults: {
          component: GoalObjectives,
          stylable: true,
          editable: true,
          void: true,
          droppable: false,
          traits: [
            {
              type: "text",
              label: "Id",
              name: "id"
            },
            {
              type: "text",
              label: "Value",
              name: "value",
            },
            {
                type: "text",
                label: "Name",
                name: "name"
            }
          ]
        }
      },
      view,
      isComponent: el => el.tagName === "GOALOBJECTIVEMUI"
    });
  }
  export default index;