import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { getHTMLIEP } from "../services"
import Grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css";
import ConfigGrapesView from "../containers/BuilderPage/ConfigGrapesView";
import "../components/app.css";
import { ACCESS_TOKEN } from "../utils/type"

const useGrapesjsView = () => {
    const { templateId } = useParams();
    const urlParams = new URLSearchParams(window.location.search);
    const domain = urlParams.get('domain');
    const token = urlParams.get('token');

    if (!!domain && !!token) {
        localStorage.setItem('BASE_URL', domain);
        localStorage.setItem(ACCESS_TOKEN, token);
    }

    const loadGrapesJs = async () => {
        const data = await getHTMLTemplate();
        if (data != null) {
                localStorage.setItem("gjs-html", data?.htmlSchool || '')
                localStorage.setItem("gjs-css", data?.cssSchool || '')
                localStorage.setItem("gjs-components", data?.componentSchool || '')
                localStorage.setItem("gjs-styles", data?.styleSchool || '')
                localStorage.setItem("gjs-assets", data?.assetSchool || '')
                localStorage.setItem("gjs-inputGrapes", data?.inputIEPSchool || '')
        }

        const editor = await Grapesjs.init(ConfigGrapesView());
        window.editor = editor;
        editor.runCommand('preview');
        // Execute a callback on all inner components starting from the root
        editor.DomComponents.getWrapper().onAll(comp => {
            comp.set({ editable: false, selectable:false, hoverable:false});
        }
        );
    };
    
    const getHTMLTemplate = async () => {
        try {
            const res = await getHTMLIEP(domain, templateId);
            return res.data;
        } catch (err) {
        }
    }

    useEffect(() => {
        loadGrapesJs();
    }, []);
 
    return  {
        getHTMLTemplate
    }
}

export default useGrapesjsView;