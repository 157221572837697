export const getFnToTextFieldHtml = (functionName = 'toTextFieldHtml') => {
    return `const ${functionName} = ({ id, label, name, value, required, fullscreen = true }) => {
        return \`
            <div class="text-field" style="margin-bottom: 8px">
                <div class="MuiFormControl-root MuiTextField-root MuiTextField-root MuiFormControl-marginNormal" style="\${fullscreen ? 'width: 100%;' : ''}">
                    <label
                        class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated \${!!value ? ' MuiInputLabel-shrink MuiFormLabel-filled' : ''} \${required ? 'Mui-required' : ''} MuiInputLabel-outlined Mui-required Mui-required"
                        data-shrink="true" for="\${id}" id="\${id}-label"
                    >
                        \${label}
                        \${required
                                ? '<span aria-hidden="true" class="MuiFormLabel-asterisk MuiInputLabel-asterisk">&thinsp;*</span>'
                                : ""
                            }
                    </label>
                    <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl">
                        <div class="MuiInputBase-input MuiOutlinedInput-input" style="height: auto;\${!value ? 'color: #e5e5e5;' : ''}">\${value || label}</div>
                        <fieldset aria-hidden="true"
                            class="PrivateNotchedOutline-root-1 MuiOutlinedInput-notchedOutline">
                            <legend
                            class="PrivateNotchedOutline-legendLabelled-3 \${!!value ? 'PrivateNotchedOutline-legendNotched-4' : ''}">
                            <span>\${label}\${required ? "&nbsp;*" : ""}</span>
                            </legend>
                        </fieldset>
                    </div>
                </div>
            </div>
        \`;
    };`
}

export const toTextFieldHtml = (functionName = 'toTextFieldHtml', variablesName) => {
    return `
        const ${variablesName} = document.querySelectorAll('TextFieldMUI')
        ${variablesName}.forEach(textField => {
            const value = textField.getAttribute('value')
            const label = textField.getAttribute('label')
            const required = textField.getAttribute('required')
            const id = textField.getAttribute('id')
            const name = textField.getAttribute('name')
            const valueString = value === \`[\${name}_\${id}]\` ? '' : value?.replaceAll?.('\\\\n', '<br/>')
            const htmlContent = ${functionName}?.({ id, label, name, value: valueString, required })
            const newItem = document.createElement('div');
            newItem.innerHTML = htmlContent
            textField?.parentElement.replaceChild(newItem, textField)
        })
    `
}